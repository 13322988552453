import yup from '~/libs/yup';

export const createSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  priceReward: yup
    .number()
    .min(10000, 'Recompensa tem que ser no mínimo R$ 100,00')
    .required()
    .label('Valor da recompensa'),
  description: yup.string().required().label('Descrição'),
  executionDate: yup.object().shape({
    start: yup.string().required().length(10).label('Data início'),
    end: yup.string().required().length(10).label('Data fim'),
  }),
  executionTime: yup.object().shape({
    start: yup.string().required().length(5).label('Hora inicial'),
    end: yup.string().required().length(5).label('Hora final'),
  }),
  checklist: yup.array(),
  dateValidity: yup.array(),
  card_id: yup.string(),
  turn: yup.string().required().label('Turno'),
  address: yup.object().shape({
    neighborhood: yup.string().required().label('Bairro'),
    zipcode: yup.string().required().label('CEP'),
    street_name: yup.string().required().label('Rua'),
    street_number: yup.string().required().label('Número'),
    city: yup.string().required().label('Cidade'),
    state: yup.string().required().label('Estado'),
  }),
  company: yup.string().length(24).required(),
});

export const disapproveSchema = yup.object().shape({
  reason: yup.string().required().label('Razão'),
  obs: yup.string().required().label('Observação'),
});

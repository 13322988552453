import React from 'react';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import Calendar from '~/assets/svg/calendar.svg';
import Clock from '~/assets/svg/clock.svg';
import Dolar from '~/assets/svg/dolar.svg';
import Button from '~/components/Button';

import { Container, Inner, Row, CompanyName, Description } from './styles';

const MissionCard = ({ item, color, fullWidth }) => {
  const history = useHistory();

  return (
    <Container fullWidth={fullWidth} color={color}>
      <Inner>
        <Row fullWidth>
          <Row>
            <img src={Calendar} />
            <Description>
              {format(new Date(item.executionDate.start), 'dd/MM/yyyy')} -{' '}
              {format(new Date(item.executionDate.end), 'dd/MM/yyyy')}
            </Description>
          </Row>

          <Row>
            <img src={Clock} />
            <Description>
              Entre {format(new Date(item.executionTime.start), 'HH:mm')} e{' '}
              {format(new Date(item.executionTime.end), 'HH:mm')}{' '}
            </Description>
          </Row>
        </Row>
        <CompanyName fullWidth>{item.name}</CompanyName>
        <Description>{item.description}</Description>

        <Button
          onClick={() => history.push(`/company/mission/${item._id}`)}
          round
          fullWidth
          color="dark"
          label="Ver detalhes"
        />
      </Inner>
    </Container>
  );
};

export default MissionCard;

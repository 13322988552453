import React, { useRef, useState } from 'react';

import Axios from 'axios';
import FadeIn from 'react-fade-in';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LogoImage from '~/assets/img/tesk-transparent.png';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Input from '~/components/Input';
import {
  Label,
  Row,
} from '~/pages/Dashboard/Users/CompanyTable/EditForm/styles';
import api from '~/services/api';
import {
  companySuccess,
  signInRequest,
  signInSuccess,
} from '~/store/modules/auth/actions';
import yupValidate from '~/utils/yupValidate';
import createSchema from '~/validators/company.schema';

import {
  Container,
  Content,
  Logo,
  TitleYellow,
  SubtitleYellow,
} from './styles';

const CreateAccount = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onCepChange = async (cep) => {
    if (cep.length === 9) {
      try {
        const response = await api.get(`/user/address/${cep}`);

        formRef.current.setData({
          address: {
            neighborhood: response.data.bairro,
            city: response.data.localidade,
            state: response.data.uf,
            street_name: response.data.logradouro,
            zipcode: response.data.cep,
          },
        });
      } catch (error) {}
    }
  };

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});

      const { success, data, errors } = await yupValidate(
        createSchema,
        formData
      );

      if (!success) {
        throw errors;
      }
      setLoading(true);

      const response = await api.post('/user', {
        name: formData.name,
        phone: formData.phone_contact,
        email: formData.email_contact,
        password: formData.password,
        tasker: false,
      });

      const { token, user } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const responseCompany = await api.post('/company', {
        ...formData,
        owner: user._id,
      });

      if (user.email_verification) {
        dispatch(companySuccess(responseCompany.data));

        dispatch(signInSuccess({ token, user }));
      } else {
        toast.success('Verifique seu email e efetue o login');
        history.push('/login');
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 401) {
          toast.error('Erro no cadastro, verifique os dados');
        }

        toast.error('Erro no cadastro, verifique os dados');
      }
      formRef.current.setErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <FadeIn>
          <Form
            style={{ marginBottom: 50 }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Logo src={LogoImage} />
            <TitleYellow>Criar conta para sua empresa</TitleYellow>
            <SubtitleYellow>Insira seus dados</SubtitleYellow>
            <div style={{ marginTop: 35 }}>
              <Input
                name="name"
                placeholder="Nome Fantasia"
                variant="border"
                fullWidth
              />
              <Input
                name="social_name"
                placeholder="Razão Social"
                variant="border"
                fullWidth
              />

              <Input
                name="cnpj"
                mask="cnpj"
                placeholder="CPNJ"
                variant="border"
                fullWidth
                returnUnmasked
              />

              <Input
                name="phone_contact"
                placeholder="Telefone de contato"
                variant="border"
                mask="cell"
                fullWidth
                returnUnmasked
              />
              <Input
                name="email_contact"
                placeholder="Email de contato"
                variant="border"
                fullWidth
              />
              <Row>
                <Input
                  name="password"
                  placeholder="Senha"
                  variant="border"
                  fullWidth
                  type="password"
                />
                <Input
                  name="confirm_password"
                  placeholder="Confirmação da Senha"
                  variant="border"
                  type="password"
                  fullWidth
                />
              </Row>

              <SubtitleYellow>Localização:</SubtitleYellow>

              <Row>
                <Input
                  name="address.zipcode"
                  placeholder="CEP"
                  returnUnmasked
                  variant="border"
                  mask="zipcode"
                  onChange={(e) => onCepChange(e.target.value)}
                  fullWidth
                />
              </Row>
              <Row>
                <Input
                  name="address.street_name"
                  placeholder="Logradouro"
                  variant="border"
                  fullWidth
                />
                <Input
                  name="address.street_number"
                  placeholder="Número"
                  variant="border"
                  fullWidth
                />
              </Row>
              <Row>
                <Input
                  name="address.complement"
                  placeholder="Complemento"
                  variant="border"
                  fullWidth
                />
                <Input
                  name="address.neighborhood"
                  placeholder="Bairro"
                  variant="border"
                  fullWidth
                />
              </Row>
              <Row>
                <Input
                  name="address.city"
                  fullWidth
                  placeholder="Cidade"
                  variant="border"
                />
                <Input
                  name="address.state"
                  fullWidth
                  placeholder="Estado"
                  variant="border"
                />
              </Row>
              <Label>Contato:</Label>
            </div>

            <Button
              loading={loading}
              type="submit"
              label="Criar conta"
              fullWidth
            />
            <Button
              onClick={() => history.push('/login')}
              color="inlineDark"
              type="submit"
              label="Voltar"
              fullWidth
            />
          </Form>
        </FadeIn>
      </Content>
    </Container>
  );
};

export default CreateAccount;

import React from 'react';

import PropTypes from 'prop-types';
import RcPagination from 'rc-pagination';
import locale from 'rc-pagination/es/locale/pt_BR';
import { IoIosMore, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Container } from './styles';

const Pagination = ({
  page,
  onChange,
  totalPages,
  pageSize,
  total,
  position,
}) => {
  // Só mostra a paginação caso o número total de páginas for maior que 1
  if (totalPages < 2) return null;

  return (
    <Container position={position}>
      <RcPagination
        current={page}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
        locale={locale}
        prevIcon={<IoIosArrowBack />}
        nextIcon={<IoIosArrowForward />}
        jumpPrevIcon={<IoIosMore />}
        jumpNextIcon={<IoIosMore />}
      />
    </Container>
  );
};

Pagination.defaultProps = {
  position: 'flex-end',
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  position: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default Pagination;

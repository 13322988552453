import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const PaperContent = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TitleInner = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: #37465b;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const TextChart = styled.div`
  color: #000000;
  padding: 5px;
  font-size: 14px;
  opacity: 0.4;
`;

export const Chart = styled.div`
  width: 150px;
`;

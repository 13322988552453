import React, { useEffect, useState } from 'react';
import Pagination from '~/components/Pagination';
import Statistics from '~/pages/DashCompany/Financial/Statistics';
import { Row } from '~/pages/DashCompany/Financial/Statistics/styles';
import api from '~/services/api';
import {
  Container,
  Content,
  TabsStyle,
  TabStyle,
  Title,
  Subtitle,
  FooterPagination,
} from '../Users/styles';
import { Col } from '../Users/UserTable/styles';
import TableList from './TableList';
import WidthdrawList from './WidthdrawList';

const INITIAL_DATA = {
  data: [],
  page: 1,
  perPage: 8,
  totalPages: 1,
};
export const Financial = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const [monthSelected, setmonthSelected] = useState();

  const [value, setValue] = React.useState(0);

  const getData = async (page = 1) => {
    try {
      setLoading(true);

      const response = await api.get(
        value === 0 ? '/finance/withdraw' : '/finance/',
        {
          params: {
            perPage: 6,
            page,
          },
        }
      );

      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [value, monthSelected]);

  const handleChangePage = (page) => getData(page);

  return (
    <Container>
      <Row>
        <Col size={40}>
          <Title>Financeiro</Title>
          <Subtitle style={{ width: '100%' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
            mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
            Vestibulum interdum, augue et euismod tincidunt, elit urna tempus
            mauris.
          </Subtitle>
        </Col>
        <Col size={60}>
          <Statistics
            setmonthSelected={setmonthSelected}
            monthSelected={monthSelected}
          />
        </Col>
      </Row>

      <TabsStyle>
        <TabStyle selected={value === 0} onClick={() => setValue(0)}>
          Saques
        </TabStyle>
        <TabStyle selected={value === 1} onClick={() => setValue(1)}>
          Entradas
        </TabStyle>
      </TabsStyle>
      <Content>
        {value === 0 ? (
          <WidthdrawList data={data} loading={loading} reflesh={getData} />
        ) : (
          <TableList data={data} loading={loading} reflesh={getData} />
        )}
      </Content>
      <FooterPagination>
        <Pagination
          onChange={handleChangePage}
          page={data.page}
          pageSize={data.limit}
          total={data.totalData}
          totalPages={data.totalPages}
          position="flex-end"
        />
      </FooterPagination>
    </Container>
  );
};

export default Financial;

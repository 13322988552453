import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: inline-block;

  padding: 15px;
  height: 200px;
  margin: 15px 5px;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ color }) =>
    color === 'white' ? '#ffffff' : '#f2f3f4'};

  @media ${devices.laptop} {
    flex-direction: row;
    width: 312px;
    ${({ fullWidth }) => fullWidth && `width: 100%;`}
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 100%;
`;

export const Description = styled.div`
  font-size: 13px;
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  color: #757f88;
  overflow: hidden;
`;

export const Row = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  img {
    margin-right: 5px;
  }
`;

export const CompanyName = styled.div`
  font-size: 18px;
  font-weight: 800;

  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  color: #37465b;
  margin-bottom: 5px;
`;

import React from 'react';

import { Popover } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Bell from '~/assets/svg/bell.svg';
import MoreVertical from '~/assets/svg/more-vertical.svg';
import Question from '~/assets/svg/question-mark-circle.svg';
import { signOutRequest } from '~/store/modules/auth/actions';

import { Container, Info, Content, Item, Message } from './styles';

const Profile = () => {
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const company = useSelector((state) => state.auth.company);
  const handleClick = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElHelp(null);
  };

  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };
  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const open1 = Boolean(anchorElHelp);
  const id1 = open1 ? 'simple-popover' : undefined;
  const open2 = Boolean(anchorElNotification);
  const id2 = open2 ? 'simple-popover' : undefined;
  const open3 = Boolean(anchorElMenu);
  const id3 = open3 ? 'simple-popover' : undefined;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickLogout = () => {
    dispatch(signOutRequest());
    history.push('/login');
  };

  return (
    <Container>
      <Info>
        <img onClick={handleClick} src={Question} alt="React Logo" />
      </Info>
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorElHelp}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Content>
          <Message>Em desenvolvimento</Message>
        </Content>
      </Popover>
      <Info>
        <img onClick={handleClickNotification} src={Bell} alt="React Logo" />
      </Info>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorElNotification}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Content>
          <Message>Nenhuma notificação</Message>
        </Content>
      </Popover>
      <Info>
        <img onClick={handleClickMenu} src={MoreVertical} alt="React Logo" />
      </Info>
      <Popover
        id={id3}
        open={open3}
        anchorEl={anchorElMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Content>
          <Item
            onClick={() =>
              company
                ? history.push('/company/profile')
                : history.push('/profile')
            }
          >
            Meu Perfil
          </Item>
          {/* <Item>Preferencias</Item> */}
          <Item onClick={handleClickLogout}>Logout</Item>
        </Content>
      </Popover>
    </Container>
  );
};

export default Profile;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 65px;

  background-color: #f2f3f4;

  z-index: 4;
`;

export const Navigation = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  > a:not(:last-of-type) {
    margin-right: 15px;
  }

  margin-left: 20px;
`;

export const MenuDrawer = styled.div`
  height: 32px;
  width: 32px;

  cursor: pointer;
  text-align: center;
  justify-content: center;

  background-color: #ffffff;
  border-radius: 19px;
  img {
    margin-top: 5px;
    height: 20px;
    width: 20px;
  }
`;

/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import StarRatingComponent from 'react-star-rating-component';

import Header from '~/assets/img/no-image-featured-image.png';
import ProfileDefault from '~/assets/img/profile.png';
import ArrowLeft from '~/assets/svg/arrow-left.svg';
import Calendar from '~/assets/svg/calendar.svg';
import Clock from '~/assets/svg/clock.svg';
import Dolar from '~/assets/svg/dolar.svg';
import Pin from '~/assets/svg/map-pin.svg';
import Button from '~/components/Button';
import { Label } from '~/components/Input/styles';
import ModalDelete from '~/components/Modals/ModalDelete';
import ModalDisapprove from '~/components/Modals/ModalDisapprove';
import ModalEditDrawer from '~/components/Modals/ModalEditDrawer';
import Separator from '~/components/Separator';
import { Row } from '~/pages/Dashboard/Components/ClockAndDate/styles';
import {
  ContentStatistics,
  TextGray,
} from '~/pages/Dashboard/Users/CompanyTable/EditForm/styles';
import api from '~/services/api';
import masks from '~/utils/masks';
import { createSchema } from '~/validators/mission.schema';

import CreateMission from '../Missions/CreateMission';
import {
  Container,
  Title,
  Subtitle,
  Content,
  Overlay,
  ContentBody,
  FooterContainer,
  ProfilePhoto,
  LabelTitle,
  LabelValue,
  LabelWhite,
  HeaderProfile,
  TitleWhite,
  RowButton,
  ButtonClose,
  ContainerDetails,
  SolicitationContent,
  SolicitationBy,
  LabelSolicitationBy,
  Col,
  Obscontainer,
  ObsContent,
  PhotoContent,
  StatusContainer,
  Status,
  LabelBlack,
} from './styles';

const MissionDetails = () => {
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);

  const { id } = useParams();

  const history = useHistory();

  const [extraData, setExtraData] = useState({});

  const modalRefApprove = useRef(null);

  const [approve, setApprove] = useState();
  const [conflit, setConflit] = useState();

  const [loading, setLoading] = useState(false);
  const [mission, setMission] = useState({});

  const modalRefEdit = useRef(null);
  const modalRef = useRef(null);

  const [currentImageFinal, setCurrentImageFinal] = useState(0);
  const [isViewerOpenFinal, setIsViewerOpenFinal] = useState(false);

  const [review, setReview] = useState({});

  const [totalMission, setTotalMission] = useState(0);
  const [totalActive, setTotalActive] = useState(0);

  const openImageViewerFinal = useCallback((index) => {
    setCurrentImageFinal(index);
    setIsViewerOpenFinal(true);
  }, []);

  const closeImageViewerFinal = () => {
    setCurrentImageFinal(0);
    setIsViewerOpenFinal(false);
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleOpenModalEdit = () => {
    setExtraData({
      checklist: mission.checklist,
      company: mission.company,
      turn: mission.turn,
    });
    modalRefEdit.current.openModal(mission);
  };

  const handleOpenModalApprove = (data = {}, aprove, conflitPayload) => {
    modalRefApprove.current.openModal(data);
    setApprove(aprove);
    setConflit(conflitPayload);
  };

  const handleOpenModalDelete = (data = {}) => {
    modalRef.current.openModal(data);
  };

  const getStatistics = async (id) => {
    try {
      const { data } = await api.get(`/user/${id}`);

      if (data.review && data.review.length) {
        setReview(data.review[0]);
      }
      setTotalMission(data.totalMission);
      setTotalActive(data.totalActive);
    } catch (err) {
      console.log(err);
    }
  };

  const getMission = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/mission/${id}`);

      if (data.tasker) {
        getStatistics(data.tasker._id);
      }

      console.log(data);
      setMission(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitAprove = async (data) => {
    try {
      const review = {
        status: approve ? 'approved' : 'disapproved',
        date: new Date(),
      };

      data.review = review;

      await api.put(
        !conflit
          ? `mission/${data._id}/finish`
          : `mission/${data._id}/resolve-conflit`,
        data
      );

      getMission();
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleDelete = async (data) => {
    try {
      await api.delete(`/mission/${data._id}`);
    } catch (err) {
    } finally {
      getMission();
      history.push('/mission');
    }
  };

  const getStatus = () => {
    if (mission.review.status === 'disapproved') {
      return false;
    }

    return true;
  };

  const handleStars = () => {
    if (review.count > 0) {
      return review.total / review.count;
    }
    return 0.0;
  };

  const handlePercent = () => {
    if (review.count > 0) {
      const percent = (review.count * 5 - review.total) / review.total;
      return (100 - percent * 100).toFixed(0);
    }
    return 0;
  };

  useEffect(() => {
    getMission();
  }, [id]);

  return (
    <Container>
      <Title>Detalhe da missão</Title>
      <Subtitle>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
        mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
        Vestibulum interdum, augue et euismod tincidunt, elit urna tempus
        mauris.
      </Subtitle>

      {!company && mission && mission._id && (
        <SolicitationBy>
          <SolicitationContent>
            <Col size="5">
              <img
                className="avatar"
                src={
                  mission.company &&
                  mission.company.assets &&
                  mission.company.assets.logo
                    ? `${mission.company.assets.logo}?${new Date().getTime()}`
                    : ProfileDefault
                }
              />
            </Col>
            <Col size="10">
              <LabelSolicitationBy>Solicitado por:</LabelSolicitationBy>
              <LabelSolicitationBy color="#19232c">
                {mission.company && mission.company.name}
              </LabelSolicitationBy>
            </Col>
            <Col size="75">
              <LabelSolicitationBy>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent et mattis n et mi finibus, varius nunc id, finibus
                felis. Vestibulum interdum, augue et euismod tincidunt, elit
                urna tempus mauris.
              </LabelSolicitationBy>
            </Col>
            <Col size="10">
              <div className="row-img" style={{ display: 'flex' }}>
                <img src={Calendar} style={{ marginRight: 5 }} />
                <LabelSolicitationBy>
                  {format(new Date(mission.createdAt), 'dd/MM/yy')}
                </LabelSolicitationBy>
              </div>
            </Col>
          </SolicitationContent>
        </SolicitationBy>
      )}
      <Content>
        <HeaderProfile>
          <Overlay>
            <RowButton>
              <ButtonClose onClick={() => history.push('/mission')}>
                <img src={ArrowLeft} />
              </ButtonClose>
              <TitleWhite>{mission.name}</TitleWhite>
              {mission.review && (
                <StatusContainer status={getStatus()}>
                  <Status>{getStatus() ? 'Aprovado' : 'Reprovado'}</Status>
                </StatusContainer>
              )}
            </RowButton>
            {mission.address && (
              <LabelWhite>
                <img src={Pin} />
                {mission.address.street_name}, {mission.address.street_number},{' '}
                {mission.address.neighborhood} - {mission.address.city} /{' '}
                {mission.address.state}, {mission.address.zipcode}
              </LabelWhite>
            )}
          </Overlay>
          {mission.company && (
            <img
              src={
                mission.company.assets && mission.company.assets.cover
                  ? `${mission.company.assets.cover}?${new Date().getTime()}`
                  : Header
              }
            />
          )}
        </HeaderProfile>

        <ContentBody>
          <div className="profile">
            <LabelTitle>Descrição:</LabelTitle>

            <LabelValue>{mission.description}</LabelValue>

            {mission.checklist && mission.checklist.length > 0 && (
              <>
                <LabelTitle>Checklist reabastecimento:</LabelTitle>

                {mission.checklist.map((element) => (
                  <LabelValue>• {element.name}</LabelValue>
                ))}
              </>
            )}

            {mission.initialPhoto && mission.initialPhoto.length > 0 && (
              <>
                <LabelTitle>Fotos iniciais:</LabelTitle>

                <PhotoContent>
                  {mission.initialPhoto.map((element, index) => (
                    <img src={element} onClick={() => openImageViewer(index)} />
                  ))}
                </PhotoContent>

                {isViewerOpen && (
                  <ImageViewer
                    src={mission.initialPhoto}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                      zIndex: 9999,
                      backgroundColor: 'rgba(0,0,0,0.9)',
                    }}
                  />
                )}
              </>
            )}

            {mission.finalPhoto && mission.finalPhoto.length > 0 && (
              <>
                <LabelTitle>Fotos finais:</LabelTitle>

                <PhotoContent>
                  {mission.finalPhoto.map((element, index) => (
                    <img
                      src={element}
                      onClick={() => openImageViewerFinal(index)}
                    />
                  ))}
                </PhotoContent>
                {isViewerOpenFinal && (
                  <ImageViewer
                    src={mission.finalPhoto}
                    currentIndex={currentImageFinal}
                    onClose={closeImageViewerFinal}
                    backgroundStyle={{
                      zIndex: 9999,
                      backgroundColor: 'rgba(0,0,0,0.9)',
                    }}
                  />
                )}
              </>
            )}

            {mission.observation && (
              <>
                <LabelTitle>Observações:</LabelTitle>

                <Obscontainer>
                  <ObsContent>
                    <LabelValue>{mission.observation}</LabelValue>
                  </ObsContent>
                </Obscontainer>
              </>
            )}
            {!company &&
              mission.report_conflit_by_company &&
              mission.report_conflit_by_company.reason && (
                <>
                  <LabelTitle>Reporte da empresa:</LabelTitle>

                  <Obscontainer>
                    <ObsContent>
                      <LabelBlack>
                        Motivo: {mission.report_conflit_by_company.reason}
                      </LabelBlack>
                      <LabelValue>
                        {mission.report_conflit_by_company.obs}
                      </LabelValue>
                      <LabelValue>
                        {format(
                          new Date(mission.report_conflit_by_company.date),
                          'dd/MM/yyyy'
                        )}
                      </LabelValue>
                    </ObsContent>
                  </Obscontainer>
                </>
              )}
            {!company &&
              mission.report_conflit_by_tasker &&
              mission.report_conflit_by_tasker.reason && (
                <>
                  <LabelTitle>Reporte do repositor:</LabelTitle>

                  <Obscontainer>
                    <ObsContent>
                      <LabelBlack>
                        Motivo: {mission.report_conflit_by_tasker.reason}
                      </LabelBlack>
                      <LabelValue>
                        {mission.report_conflit_by_tasker.obs}
                      </LabelValue>
                      <LabelValue>
                        {format(
                          new Date(mission.report_conflit_by_tasker.date),
                          'dd/MM/yyyy'
                        )}
                      </LabelValue>
                    </ObsContent>
                  </Obscontainer>
                </>
              )}
          </div>

          <div className="profileinfo">
            {mission.tasker && (
              <>
                <ProfilePhoto>
                  <img
                    src={
                      mission.tasker.profile_photo &&
                      mission.tasker.profile_photo !== ''
                        ? mission.tasker.profile_photo
                        : ProfileDefault
                    }
                  />
                </ProfilePhoto>
                <Row
                  style={{
                    marginBottom: 25,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  <TextGray style={{ width: '100%' }}>
                    Pontuação: {handlePercent()}/100
                  </TextGray>
                  <StarRatingComponent
                    editing={false}
                    name="rate1"
                    starCount={5}
                    value={handleStars()}
                    renderStarIcon={(index, value) => {
                      return (
                        <span
                          style={index <= value ? { color: '#ffc107' } : {}}
                        >
                          <i
                            className={
                              index <= value ? 'fas fa-star' : 'far fa-star'
                            }
                          />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span style={{ color: '#ffc107' }}>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <Label
                    style={{
                      textAlign: 'center',
                      fontSize: 13,
                      marginTop: 5,
                      textTransform: 'capitalize',
                    }}
                  >
                    {mission.tasker.name}
                  </Label>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: 25,
                      justifyContent: 'center',
                      marginBottom: 25,
                    }}
                  >
                    <ContentStatistics>
                      <Label style={{ textAlign: 'center', fontSize: 13 }}>
                        Total
                      </Label>
                      <TextGray>{totalMission}</TextGray>
                    </ContentStatistics>
                    <ContentStatistics>
                      <Label style={{ textAlign: 'center', fontSize: 13 }}>
                        Ativas
                      </Label>
                      <TextGray>{totalMission - totalActive}</TextGray>
                    </ContentStatistics>
                    <ContentStatistics>
                      <Label style={{ textAlign: 'center', fontSize: 13 }}>
                        Finalizadas
                      </Label>
                      <TextGray>{totalActive}</TextGray>
                    </ContentStatistics>
                  </div>
                </Row>
              </>
            )}
            {mission.executionDate && (
              <ContainerDetails>
                <div className="contentDetails">
                  <LabelTitle>Data:</LabelTitle>

                  <div className="row-img">
                    <img src={Calendar} />
                    <LabelValue>
                      {format(
                        new Date(mission.executionDate.start),
                        'dd/MM/yy'
                      )}
                    </LabelValue>
                  </div>
                  <LabelTitle>Horário de execução:</LabelTitle>

                  <div className="row-img">
                    <img src={Clock} />
                    <LabelValue>
                      {format(new Date(mission.executionTime.start), 'HH:mm')}
                    </LabelValue>
                  </div>
                  <LabelTitle>Valor:</LabelTitle>

                  <div className="row-img">
                    <img src={Dolar} />
                    <LabelValue>
                      R$ {masks.currency(mission.priceReward)}
                    </LabelValue>
                  </div>
                </div>
              </ContainerDetails>
            )}
          </div>
        </ContentBody>
        <Separator />
        {mission &&
          !company &&
          mission.status === 'f_1-finished' &&
          (!mission.conflit || mission.conflit === 'pending') &&
          mission.review &&
          mission.review.status === 'disapproved' && (
            <div>
              <LabelTitle style={{ marginLeft: 30 }}>
                Resolução de conflito:
              </LabelTitle>

              <FooterContainer>
                <div style={{ marginRight: 15 }}>
                  <Button
                    color="outline-dark"
                    label="Reprovar"
                    width="150"
                    onClick={() => handleOpenModalApprove(mission, false, true)}
                    round={20}
                  />
                </div>
                <Button
                  color="action"
                  onClick={() => handleOpenModalApprove(mission, true, true)}
                  label="aprovar"
                  width="150"
                  round={20}
                />
              </FooterContainer>
            </div>
          )}
        {mission &&
          !company &&
          mission.status === 'f_1-finished' &&
          (!mission.conflit || mission.conflit === 'pending') &&
          mission.review &&
          mission.review.status === 'approved' && (
            <div>
              <LabelTitle style={{ marginLeft: 30 }}>
                Resolução de conflito:
              </LabelTitle>

              <FooterContainer>
                <Button
                  color="action"
                  onClick={() => handleOpenModalApprove(mission, true, true)}
                  label="Finalizar"
                  width="150"
                  round={20}
                />
              </FooterContainer>
            </div>
          )}
        {mission && mission.status === 'f_1-finished' && !mission.review && (
          <FooterContainer>
            <div style={{ marginRight: 15 }}>
              <Button
                color="outline-dark"
                label="Reprovar"
                width="150"
                onClick={() => handleOpenModalApprove(mission, false)}
                round={20}
              />
            </div>
            <Button
              color="action"
              onClick={() => handleOpenModalApprove(mission, true)}
              label="aprovar"
              width="150"
              round={20}
            />
          </FooterContainer>
        )}

        {company && mission && mission.status === 'w_1-pending' && (
          <FooterContainer>
            <div style={{ marginRight: 15 }}>
              <Button
                color="outline-dark"
                label="Excluir"
                width="150"
                onClick={() => handleOpenModalDelete(mission)}
                round={20}
              />
            </div>
            {!mission.tasker && (
              <Button
                color="action"
                onClick={() => handleOpenModalEdit(mission)}
                label="Editar"
                width="150"
                round={20}
              />
            )}
          </FooterContainer>
        )}
      </Content>

      <ModalEditDrawer
        title="Cadastrar nova missão"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas."
        ref={modalRefEdit}
        onSave={() => getMission()}
        url="mission"
        extraData={extraData}
        validation={createSchema}
      >
        <CreateMission extraData={extraData} setExtraData={setExtraData} />
      </ModalEditDrawer>
      <ModalDisapprove
        ref={modalRefApprove}
        approve={approve}
        message={
          approve
            ? `Tem certeza que deseja aprovar
            essa missão? Você irá liberar o valor de R$ ${masks.currency(
              mission.priceReward - mission.tax_fee
            )}`
            : 'Tem certeza que deseja reprovar essa missão ? '
        }
        onSave={handleSubmitAprove}
      />
      <ModalDelete
        ref={modalRef}
        message="Tem certeza que deseja remover essa missão ? "
        onSave={handleDelete}
      />
    </Container>
  );
};

export default MissionDetails;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #37465b;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  width: 100%;
  max-height: 70px;
`;

export const CardHolder = styled.span`
  font-size: 14px;
  color: #757f88;
`;

export const Number = styled.span`
  font-size: 18px;
  color: #37465b;
  width: 100%;
`;

export const InnerContent = styled.div`
  display: inline-block;
`;
export const ContentIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${({ noMargin }) => !noMargin && 'padding: 10px;'}
  @media ${devices.tablet} {
    width: ${({ size }) => size}%;
  }
  .select {
    width: 100%;
  }
`;

export const Title = styled.div`
  color: #aeb2bb;
  font-size: 12px;
  width: 100%;
`;

export const Subtitle = styled.div`
  color: #4c5a73;
  font-size: 19px;
  font-weight: bold;
  width: 100%;

  margin-top: 5px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ margin }) => margin && 'margin-left: auto;'};
`;

import styled, { css, keyframes } from 'styled-components';
import Form from '~/components/Form';

export const MessageModal = styled.span`
  font-size: 18px;
  text-align: center;
  color: #212121;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FormView = styled(Form)`
  margin-top: 25px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  height: 250px;
  flex-wrap: wrap;
`;

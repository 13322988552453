import React, { useRef } from 'react';

import FadeIn from 'react-fade-in';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LogoImage from '~/assets/img/tesk-transparent.png';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Input from '~/components/Input';
import { signInRequest } from '~/store/modules/auth/actions';
import yupValidate from '~/utils/yupValidate';
import loginSchema from '~/validators/login.schema';

import {
  Container,
  Content,
  Logo,
  TitleYellow,
  SubtitleYellow,
  SubTitleClick,
  SubtitleSignUp,
} from './styles';

const Login = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);

  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});
      const { success, data, errors } = await yupValidate(
        loginSchema,
        formData
      );

      if (!success) {
        throw errors;
      }
      dispatch(signInRequest(formData));
    } catch (error) {
      if (error.response) {
        const { status, errors } = error.response;

        if (status === 401) {
          return console.log('Error 401', 'Credenciais inválidas');
        }

        return console.log('Error 500');
      }
      formRef.current.setErrors(error);
    }
  };

  return (
    <Container>
      <Content>
        <FadeIn>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Logo src={LogoImage} />
            <TitleYellow>Login</TitleYellow>
            <SubtitleYellow>Insira seus dados de acesso</SubtitleYellow>
            <SubtitleSignUp>
              Caso sua empresa não seja cadastrada na plataforma MyTesk{' '}
              <SubTitleClick onClick={() => history.push('create-account')}>
                clique aqui
              </SubTitleClick>{' '}
              para realizar seu cadastro
            </SubtitleSignUp>

            <div style={{ marginTop: 35 }}>
              <Input
                name="email"
                placeholder="E-mail"
                variant="border"
                fullWidth
              />
              <Input
                type="password"
                name="password"
                placeholder="Senha"
                variant="border"
                fullWidth
              />
            </div>

            <Button loading={loading} type="submit" label="Login" fullWidth />
          </Form>
        </FadeIn>
      </Content>
    </Container>
  );
};

export default Login;

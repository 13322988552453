/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileDefault from '~/assets/img/profile.png';

import Edit from '~/assets/svg/edit-2.svg';

import Button from '~/components/Button';
import ImageUpload from '~/components/ImageUpload';
import ModalEditDrawer from '~/components/Modals/ModalEditDrawer';
import Separator from '~/components/Separator';
import api from '~/services/api';
import { getMeRequest } from '~/store/modules/auth/actions';
import EditForm from './EditForm';

import { LoaderComponent } from '../../../components/Button/styles';

import {
  Container,
  Title,
  Subtitle,
  Content,
  EditContent,
  ContentBody,
  FooterContainer,
  ProfilePhoto,
  ContentInfo,
  LabelTitle,
  LabelValue,
} from './styles';

const Profile = () => {
  const user = useSelector((state) => state.auth.user);

  const modalRefEdit = useRef(null);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModalEdit = () => {
    modalRefEdit.current.openModal(user);
  };

  const reflesh = () => {
    dispatch(getMeRequest());
  };

  const handleChangeProfile = async (e) => {
    setLoading(true);
    try {
      const pic = e.target.files[0];

      const body = new FormData();
      body.append('file', pic);

      await api.put('/user/profile-photo', body);

      setTimeout(() => {
        reflesh();
      }, 1000);
    } catch (err) {
      console.log(err.response.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Meu Perfil</Title>
      <Subtitle>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
        mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
        Vestibulum interdum, augue et euismod tincidunt, elit urna tempus
        mauris.
      </Subtitle>
      <Content>
        {/*         <HeaderProfile>
          <img src={Header} />
        </HeaderProfile> */}
        <ContentBody>
          <div className="profile">
            <ImageUpload onChange={handleChangeProfile}>
              <ProfilePhoto>
                {!loading ? (
                  <>
                    <img
                      src={
                        user.profile_photo
                          ? `${user.profile_photo}?${new Date().getTime()}`
                          : ProfileDefault
                      }
                    />
                    <EditContent>
                      <img src={Edit} />
                    </EditContent>
                  </>
                ) : (
                  <LoaderComponent
                    type="ThreeDots"
                    color="#ffc107"
                    height={20}
                    width={50}
                    //3 secs
                  />
                )}
              </ProfilePhoto>
            </ImageUpload>
          </div>

          <div className="profileinfo">
            <ContentInfo>
              <LabelTitle>Informações basicas:</LabelTitle>
              <LabelValue>{user.name}</LabelValue>
              <LabelValue>{user.phone}</LabelValue>
              <LabelValue>{user.email}</LabelValue>
            </ContentInfo>
          </div>
        </ContentBody>
        <Separator />
        <FooterContainer>
          <Button
            color="action"
            onClick={handleOpenModalEdit}
            label="Editar"
            width="150"
            round={20}
          />
        </FooterContainer>
      </Content>
      <ModalEditDrawer
        title="Visualizar perfil"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas."
        ref={modalRefEdit}
        onSave={reflesh}
        url={'user'}
      >
        <EditForm data={user} />
      </ModalEditDrawer>
    </Container>
  );
};

export default Profile;

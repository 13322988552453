import React from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { IoIosClose } from 'react-icons/io';

import { Container, Content, ButtonClose, Title, Controls } from './styles';

const Modal = ({ title, children, isOpen, closeModal, maxWidth }) => {
  if (isOpen)
    return createPortal(
      <Container>
        <Content maxWidth={maxWidth}>
          <ButtonClose type="button" onClick={closeModal}>
            <IoIosClose />
          </ButtonClose>
          <div>{children}</div>
        </Content>
      </Container>,
      document.body
    );

  return null;
};

Modal.defaultProps = {
  initialData: {},
  maxWidth: 500,
};

Modal.propTypes = {
  initialData: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Modal;

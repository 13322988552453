import React from 'react';

import { format } from 'date-fns';

import Calendar from '~/assets/svg/calendar.svg';
import Dolar from '~/assets/svg/dolar.svg';
import { LoaderComponent } from '~/components/Button/styles';
import { Avatar } from '~/components/Menu/Profile/styles';
import masks from '~/utils/masks';

import {
  Container,
  Row,
  RowHeader,
  Col,
  ContentRow,
  HeaderText,
  NameLabel,
  BodyEmpty,
  SubLabel,
  TextEmpty,
} from '../../../Dashboard/Users/UserTable/styles';
import { Status, StatusContainer } from './styles';

const TableList = ({ data, loading, reflesh }) => {
  const getStatus = (status) => {
    if (status === 'w_1-pending') return 'Pendente';
    if (status === 'd_1-executed') return 'Aprovado';
    if (status === 'd_1-canceled') return 'Reprovado';
  };

  return (
    <Container>
      <RowHeader>
        <Col size={50}>
          <HeaderText>Descrição:</HeaderText>
        </Col>
        <Col size={15}>
          <HeaderText>Data:</HeaderText>
        </Col>
        <Col size={20}>
          <HeaderText>Recompensa:</HeaderText>
        </Col>
        <Col size={15} />
      </RowHeader>

      {!loading && data.data.length === 0 && (
        <BodyEmpty>
          <TextEmpty>Nenhum dado encontrado!</TextEmpty>
        </BodyEmpty>
      )}

      {loading && (
        <LoaderComponent
          type="ThreeDots"
          color="#37465b"
          height={100}
          width={100}
          // 3 secs
        />
      )}

      {!loading &&
        data.data.map((element, index) => (
          <ContentRow>
            <Col size={15}>
              <Row>
                <StatusContainer status={element.status}>
                  <Status status={element.status}>
                    {getStatus(element.status)}
                  </Status>
                </StatusContainer>
              </Row>
            </Col>
            <Col size={35}>
              <Row>
                <NameLabel>
                  {element.mission_id && element.mission_id.name}
                </NameLabel>
                <SubLabel full>
                  {element.mission_id && element.mission_id.description}
                </SubLabel>
              </Row>
            </Col>

            <Col size={15}>
              <Row>
                <img src={Calendar} />
                <SubLabel>
                  {format(new Date(element.createdAt), 'dd/MM/yyyy')}
                </SubLabel>
              </Row>
            </Col>
            <Col size={20}>
              <Row>
                <img src={Dolar} />
                <SubLabel>R$ {masks.currency(element.value)}</SubLabel>
              </Row>
            </Col>

            <Col noMargin size={15}>
              {/* {openEdit && openEditIndex === index ? (
                <RowAction margin>
                  <EditIcon
                    onClick={() => {
                      handleOpenModalEdit(element);
                    }}
                  >
                    <IconImage src={Edit} />
                  </EditIcon>
                  <DeleteIcon
                    onClick={() => {
                      handleOpenModalDelete(element);
                    }}
                  >
                    <IconImage src={Trash} />
                  </DeleteIcon>
                </RowAction>
              ) : (
                <RowAction>
                  <BlankIcon />
                  <EditVertical
                    onClick={() => {
                      setOpenEdit(true);
                      setOpenEditIndex(index);
                    }}
                  >
                    <IconImage fill="white" src={MenuVertical} />
                  </EditVertical>
                </RowAction> 
              )} */}
            </Col>
          </ContentRow>
        ))}
    </Container>
  );
};

export default TableList;

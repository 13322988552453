import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const FooterPagination = styled.div`
  justify-content: flex-end;

  width: 100%;
  margin-bottom: 35px;
`;

export const TabsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TabStyle = styled.div`
  color: ${({ selected }) => (selected ? '#ff9e00' : '#9ca3aa')};
  cursor: pointer;
  font-size: 17px;
  font-weight: 800;
  padding: 10px 35px;
  border-bottom: 1px solid
    ${({ selected }) => (selected ? '#ff9e00' : '#9ca3aa')};
`;

export const Head = styled.div`
  grid-column: 1fr;

  @media ${devices.laptop} {
    grid-column: span 5;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #37465b;
  width: 100%;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #666666;
  width: 70%;
`;

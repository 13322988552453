import React, { useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import ProfilePhoto from '~/assets/img/profile.png';
import Calendar from '~/assets/svg/calendar.svg';
import Check from '~/assets/svg/checkmark-circle-2.svg';
import Clock from '~/assets/svg/clock.svg';
import Close from '~/assets/svg/close.svg';
import Dolar from '~/assets/svg/dolar.svg';
import MenuVertical from '~/assets/svg/more-verticalwhite.svg';
import Button from '~/components/Button';
import { LoaderComponent } from '~/components/Button/styles';
import { Avatar } from '~/components/Menu/Profile/styles';
import ModalDelete from '~/components/Modals/ModalDelete';
import {
  ContentStatus,
  MissionDetails,
  SubLabelStatus,
} from '~/pages/DashCompany/Missions/MissionTable/styles';
import api from '~/services/api';
import masks from '~/utils/masks';

import {
  Container,
  Row,
  Col,
  ContentRow,
  NameLabel,
  BodyEmpty,
  SubLabel,
  TextEmpty,
  IconImage,
  EditVertical,
  BlankIcon,
  RowAction,
  DeleteIcon,
  EditIcon,
  SubLabelDate,
} from '../../Users/UserTable/styles';
import { TitleInner } from '../MissoesChart/styles';
import { TaskerName } from './styles';

const LastPendingMission = ({ data, loading, reflesh }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditIndex, setOpenEditIndex] = useState();
  const [approve, setApprove] = useState(false);
  const [selected, setSelected] = useState();
  const [loadingFinish, setLoadingFinish] = useState();

  const history = useHistory();
  const modalRef = useRef();

  const handleOpenModal = (data = {}, aprove) => {
    modalRef.current.openModal(data);
    setApprove(aprove);
    setSelected(data);
    console.log(data);
  };

  const handleSubmit = async (data) => {
    setLoadingFinish(true);
    try {
      const review = {
        status: approve ? 'approved' : 'disapproved',
        date: new Date(),
      };

      await api.put(`mission/${data._id}/resolve-conflit`, { review });

      reflesh();
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingFinish(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <TitleInner>Últimas Pendências</TitleInner>

      {!loading && data.data.length === 0 && (
        <BodyEmpty>
          <TextEmpty>Nenhum dado encontrado!</TextEmpty>
        </BodyEmpty>
      )}

      {loading && (
        <LoaderComponent
          type="ThreeDots"
          color="#37465b"
          height={100}
          width={100}
          // 3 secs
        />
      )}

      {!loading && (
        <>
          {data.data.map((element, index) => (
            <ContentRow>
              <Col size={10}>
                <ContentStatus status={element.review.status}>
                  <SubLabelStatus status={element.review.status}>
                    {element.review.status === 'approved'
                      ? 'Aprovada'
                      : 'Reprovada'}
                  </SubLabelStatus>
                </ContentStatus>
              </Col>
              <Col size={20}>
                <Row>
                  <Avatar
                    src={
                      (element.tasker && element.tasker.profile_photo !== '') ||
                      ProfilePhoto
                    }
                  />
                  <TaskerName>{element.description}</TaskerName>
                </Row>
              </Col>
              <Col size={15}>
                <MissionDetails
                  onClick={() => history.push(`mission/${element._id}`)}
                >
                  <NameLabel>{element.name}</NameLabel>
                </MissionDetails>
              </Col>

              <Col size={15}>
                <Row>
                  <img src={Calendar} />
                  <SubLabelDate>
                    {format(
                      new Date(element.executionDate.start),
                      'dd/MM/yyyy'
                    )}{' '}
                    -{' '}
                    {format(new Date(element.executionDate.end), 'dd/MM/yyyy')}
                  </SubLabelDate>
                </Row>
              </Col>
              <Col size={20}>
                <Row>
                  {element.executionTime ? (
                    <>
                      <img src={Clock} />
                      <SubLabelDate>
                        Entre{' '}
                        {format(new Date(element.executionTime.start), 'HH:mm')}{' '}
                        e {format(new Date(element.executionTime.end), 'HH:mm')}{' '}
                      </SubLabelDate>
                    </>
                  ) : (
                    '-'
                  )}
                </Row>
              </Col>
              <Col size={10}>
                <Row>
                  <img src={Dolar} />
                  <SubLabel>R$ {masks.currency(element.priceReward)}</SubLabel>
                </Row>
              </Col>
              <Col noMargin size={10}>
                {openEdit && openEditIndex === index ? (
                  <RowAction margin>
                    <EditIcon
                      onClick={() => {
                        handleOpenModal(element, false);
                      }}
                    >
                      <IconImage src={Close} />
                    </EditIcon>
                    <DeleteIcon
                      onClick={() => {
                        handleOpenModal(element, true);
                      }}
                    >
                      <IconImage src={Check} />
                    </DeleteIcon>
                  </RowAction>
                ) : (
                  <RowAction>
                    <BlankIcon />
                    <EditVertical
                      onClick={() => {
                        setOpenEdit(true);
                        setOpenEditIndex(index);
                      }}
                    >
                      <IconImage fill="white" src={MenuVertical} />
                    </EditVertical>
                  </RowAction>
                )}
              </Col>
            </ContentRow>
          ))}
          <Button
            onClick={() => history.push('/conflit')}
            label="Ver todas pendências"
            color="inline"
          />
          <ModalDelete
            ref={modalRef}
            message={
              approve
                ? `Tem certeza que deseja aprovar
            essa missão? Você irá liberar o valor de R$ ${masks.currency(
              selected.priceReward - selected.tax_fee
            )}`
                : 'Tem certeza que deseja reprovar essa missão ? '
            }
            onSave={handleSubmit}
          />
        </>
      )}
    </Container>
  );
};

export default LastPendingMission;

import React from 'react';
import Chart from 'react-apexcharts';

import { Container, Row, Card, TitleCard, SubtitleCard } from './styles';

const StatisticsContainer = ({}) => {
  let series = [
    {
      name: 'Desktops',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];
  let options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
  };
  return (
    <Container>
      <Row>
        <Card
          style={{
            marginRight: '15px',
          }}
        >
          <TitleCard>Missões na semana </TitleCard>
          <SubtitleCard>Lorem ipsum dolor sit amet consectetu</SubtitleCard>
          <Chart options={options} series={series} type="line" width={'100%'} />
        </Card>
        <Card>
          <TitleCard>Missões no ano </TitleCard>
          <SubtitleCard>Lorem ipsum dolor sit amet consectetu</SubtitleCard>
          <Chart options={options} series={series} type="line" width={'100%'} />
        </Card>
      </Row>
    </Container>
  );
};

export default StatisticsContainer;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import StarRatingComponent from 'react-star-rating-component';

import Profile from '~/assets/img/profile.png';
import Input from '~/components/Input';
import api from '~/services/api';

import {
  Label,
  Row,
  ContainerProfile,
  ProfilePhoto,
  Content,
  TextGray,
  ContentStatistics,
  DocumentPhoto,
} from './styles';

const EditForm = ({ data = {}, refModal }) => {
  const [review, setReview] = useState({});

  const [totalMission, setTotalMission] = useState(0);
  const [totalActive, setTotalActive] = useState(0);

  const getStatistics = async (id) => {
    try {
      const { data } = await api.get(`/user/${id}`);

      if (data.review && data.review.length) {
        setReview(data.review[0]);
      }
      setTotalMission(data.totalMission);
      setTotalActive(data.totalActive);
    } catch (err) {
      console.log(err);
    }
  };

  const handleStars = () => {
    if (review.count > 0) {
      return review.total / review.count;
    }
    return 0;
  };

  const handlePercent = () => {
    if (review.count > 0) {
      const percent = (review.count * 5 - review.total) / review.total;
      return (100 - percent * 100).toFixed(0);
    }
    return 0;
  };

  const onCepChange = async (cep) => {
    if (cep.length === 9) {
      try {
        const response = await api.get(`/user/address/${cep}`);

        refModal.current.setValues({
          address: {
            neighborhood: response.data.bairro,
            city: response.data.localidade,
            state: response.data.uf,
            street_name: response.data.logradouro,
            zipcode: response.data.cep,
          },
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (data !== {}) {
      getStatistics(data._id);
    }
  }, [data]);

  return (
    <div
      style={{
        padding: 25,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <Row
        style={{
          marginBottom: 25,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <ContainerProfile>
          <ProfilePhoto
            src={data.profile_photo ? data.profile_photo : Profile}
          />
        </ContainerProfile>
        <Content>
          <TextGray>Pontuação: {handlePercent()}/100</TextGray>
          <StarRatingComponent
            editing={false}
            name="rate1"
            starCount={5}
            value={handleStars()}
            renderStarIcon={(index, value) => {
              return (
                <span style={index <= value ? { color: '#ffc107' } : {}}>
                  <i
                    className={index <= value ? 'fas fa-star' : 'far fa-star'}
                  />
                </span>
              );
            }}
            renderStarIconHalf={() => {
              return (
                <span style={{ color: '#ffc107' }}>
                  <span style={{ position: 'absolute' }}>
                    <i className="far fa-star" />
                  </span>
                  <span>
                    <i className="fas fa-star-half" />
                  </span>
                </span>
              );
            }}
          />
        </Content>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 25,
            justifyContent: 'center',
          }}
        >
          <ContentStatistics>
            <Label style={{ textAlign: 'center' }}>Total</Label>
            <TextGray>{totalMission}</TextGray>
          </ContentStatistics>
          <ContentStatistics>
            <Label style={{ textAlign: 'center' }}>Ativas</Label>
            <TextGray>{totalMission - totalActive}</TextGray>
          </ContentStatistics>
          <ContentStatistics>
            <Label style={{ textAlign: 'center' }}>Finalizadas</Label>
            <TextGray>{totalActive}</TextGray>
          </ContentStatistics>
        </div>
      </Row>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Label>Informações:</Label>
        <Row>
          <Input
            name="name"
            placeholder="Nome empresa"
            variant="border"
            fullWidth
          />
          <Input
            name="phone"
            placeholder="Telefone de contato"
            variant="border"
            mask="cell"
            fullWidth
          />
        </Row>
        <Input
          name="email"
          disabled
          placeholder="Email de contato"
          variant="border"
          fullWidth
        />

        <Label>Localização:</Label>

        <Row>
          <Input
            name="address.zipcode"
            placeholder="CEP"
            variant="border"
            mask="zipcode"
            onChange={(e) => onCepChange(e.target.value)}
            fullWidth
          />
        </Row>
        <Row>
          <Input
            name="address.street_name"
            placeholder="Rua"
            variant="border"
            fullWidth
          />
          <Input
            name="address.street_number"
            placeholder="Número"
            variant="border"
            fullWidth
          />
        </Row>
        <Row>
          <Input
            name="address.complement"
            placeholder="Complemento"
            variant="border"
            fullWidth
          />
          <Input
            name="address.neighborhood"
            placeholder="Bairro"
            variant="border"
            fullWidth
          />
        </Row>
        <Row>
          <Input
            name="address.city"
            fullWidth
            placeholder="Cidade"
            variant="border"
          />
          <Input
            name="address.state"
            fullWidth
            placeholder="Estado"
            variant="border"
          />
        </Row>
        <Row>
          {data.documents &&
            data.documents.cnhBack &&
            data.documents.cnhBack.photo && (
              <div>
                <Label>Frente CNH</Label>
                <DocumentPhoto src={data.documents.cnhFront.photo} />
              </div>
            )}
          {data.documents &&
            data.documents.cnhFront &&
            data.documents.cnhFront.photo && (
              <div>
                <Label>Verso CNH</Label>
                <DocumentPhoto src={data.documents.cnhFront.photo} />
              </div>
            )}
        </Row>
      </div>
    </div>
  );
};

export default EditForm;

import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;

  display: flex;
  flex-wrap: wrap;
`;

export const Head = styled.div`
  grid-column: 1fr;

  @media ${devices.laptop} {
    grid-column: span 5;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #37465b;
  width: 100%;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #666666;
  width: 70%;
`;

export const HeaderProfile = styled.div`
  height: 230px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  background-color: #eeeeee;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
`;

export const ProfilePhoto = styled.div`
  height: 121px;
  width: 121px;

  @media ${devices.laptop} {
    position: absolute;
    top: -33%;
    left: 140px;
  }
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
  display: flex;

  border: 1px solid #ffbc00;
  border-radius: 100px;
  img {
    height: 115px;
    width: 115px;
    border-radius: 100px;
  }
`;

export const ContentInfo = styled.div`
  padding: 0px 0px 0px 25px;
  text-align: left;
`;

export const FooterContainer = styled.div`
  padding: 25px;
`;

export const EditContent = styled.div`
  position: absolute;
  cursor: pointer;
  background-color: #ffbc00;
  border-radius: 100px;

  bottom: 5px;
  right: 5px;
  img {
    width: 38px;
    height: 32px;
    border-radius: 100px;
  }
`;

export const ContentBody = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  border-radius-top-right: 15px;
  border-radius-top-left: 15px;
  text-align: center;

  .profile {
    width: 40%;
    padding-top: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .profileinfo {
    margin-top: 15px;
    border-left-width: 2px;
    border-left-color: #eeeeee;
    border-left-style: solid;
    width: 60%;
  }
`;

export const Content = styled.div`
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
`;

export const LabelTitle = styled.h1`
  font-size: 17px;
  color: #273645;
  margin-top: 15px;
`;

export const LabelValue = styled.h1`
  font-size: 15px;
  font-weight: normal;
  margin: 10px 0px;
  color: #757f88;
`;

import React from 'react';

import {
  RowHeader,
  Col,
  HeaderText,
} from '~/pages/Dashboard/Users/UserTable/styles';

function TableHeader() {
  return (
    <RowHeader>
      <Col size={10}>
        <HeaderText>Status:</HeaderText>
      </Col>
      <Col size={20}>
        <HeaderText>Executor:</HeaderText>
      </Col>
      <Col size={15}>
        <HeaderText>Título:</HeaderText>
      </Col>
      <Col size={15}>
        <HeaderText>Data</HeaderText>
      </Col>
      <Col size={20}>
        <HeaderText>Horário</HeaderText>
      </Col>
      <Col size={10}>
        <HeaderText>Recompensa</HeaderText>
      </Col>
      <Col size={10}>
        <HeaderText />
      </Col>
    </RowHeader>
  );
}

export default TableHeader;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px 0 30px;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;

  background-color: #f5f5f5;
  background-image: ${({ src }) => `url('${src}')`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 15px;
  border-radius: 50%;
`;

export const Info = styled.div`
  margin: 0 20px 0 15px;
  cursor: pointer;
`;

export const Message = styled.div`
  color: #666666;
  font-size: 15px;
`;

export const Item = styled.div`
  cursor: pointer;
  margin: 15px 0px;
  color: #37465b;
  font-size: 15px;
`;

export const Content = styled.div`
  padding: 15px;
`;

import React from 'react';

import { Container, Content, Row, Text } from './styles';
import Clock from '~/assets/svg/clock.svg';
import { format } from 'date-fns';

function ClockAndDate() {
  return (
    <Container>
      <Content>
        <Row>
          <img src={Clock} />
          <Text>{format(new Date(), 'hh:mm aa dd/mm')}</Text>
        </Row>
      </Content>
    </Container>
  );
}

export default ClockAndDate;

import styled from 'styled-components';

export const Container = styled.div`
  width: 200px;
  height: 48px;
  object-fit: contain;
  border-radius: 24px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
`;

export const Content = styled.div`
  padding: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-left: 5px;
  color: #757f88;
`;

import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import ModalEditDrawer from '~/components/Modals/ModalEditDrawer';
import Pagination from '~/components/Pagination';
import {
  Content,
  FooterPagination,
  TabsStyle,
  TabStyle,
} from '~/pages/Dashboard/Users/styles';
import api from '~/services/api';
import { createSchema } from '~/validators/mission.schema';

import CreateMission from './CreateMission';
import MissionTable from './MissionTable';
import { Container, Title, Subtitle, RowContent } from './styles';

const INITIAL_DATA = {
  data: [],
  page: 1,
  perPage: 8,
  totalPages: 1,
};
const Missions = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const company = useSelector((state) => state.auth.company);

  const [extraData, setExtraData] = useState({});

  const [value, setValue] = React.useState(0);

  const modalRefEdit = useRef(null);

  const handleOpenModalCreate = () => {
    modalRefEdit.current.openModal();
  };

  const getData = async (page = 1) => {
    try {
      setLoading(true);

      const query = {
        perPage: 6,
        page,
      };

      if (value === 0) {
        query.status = 'w_1-pending';
      } else if (value === 1) {
        query.status = 'd_1-accepted,d_2-doing,f_1-finished';
        query.reviewed = false;
      } else if (value === 2) {
        query.status = 'f_1-finished,f_2-canceled';
        query.reviewed = true;
      }

      const response = await api.get('/mission', {
        params: query,
      });

      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const reflesh = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, [value]);

  const handleChangePage = (page) => getData(page);

  return (
    <>
      <Container>
        <Title>Minhas missões</Title>
        <RowContent>
          <Subtitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
            mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
            Vestibulum interdum, augue et euismod tincidunt.
          </Subtitle>
          {company && (
            <Button
              label="Criar nova missão"
              round
              color="darkYellow"
              onClick={() => handleOpenModalCreate()}
            />
          )}
        </RowContent>

        <TabsStyle>
          <TabStyle selected={value === 0} onClick={() => setValue(0)}>
            Novas
          </TabStyle>
          <TabStyle selected={value === 1} onClick={() => setValue(1)}>
            Pendentes
          </TabStyle>
          <TabStyle selected={value === 2} onClick={() => setValue(2)}>
            Finalizadas
          </TabStyle>
        </TabsStyle>
        <Content>
          {value === 0 && (
            <MissionTable
              canEdit
              reflesh={getData}
              data={data}
              loading={loading}
            />
          )}
          {value === 1 && (
            <MissionTable
              canAprove
              reflesh={getData}
              data={data}
              loading={loading}
            />
          )}
          {value === 2 && (
            <MissionTable
              finished
              reflesh={getData}
              data={data}
              loading={loading}
            />
          )}
        </Content>
        <FooterPagination>
          <Pagination
            onChange={handleChangePage}
            page={data.page}
            pageSize={data.limit}
            total={data.totalData}
            totalPages={data.totalPages}
            position="flex-end"
          />
        </FooterPagination>
      </Container>
      <ModalEditDrawer
        title="Cadastrar nova missão"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas."
        ref={modalRefEdit}
        onSave={reflesh}
        url="mission"
        create
        extraData={extraData}
        validation={createSchema}
      >
        <CreateMission setExtraData={setExtraData} refModal={modalRefEdit} />
      </ModalEditDrawer>
    </>
  );
};

export default Missions;

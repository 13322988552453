import { toast } from 'react-toastify';
import { all, takeLatest, call, put } from 'redux-saga/effects';

import api from '~/services/api';

import {
  signInSuccess,
  signInError,
  companySuccess,
  companyRequest,
  getMeSuccess,
} from './actions';

export function* getMeUser({ payload }) {
  try {
    const res = yield call(api.get, '/me');

    yield put(getMeSuccess(res.data.user));
    if (res.data.company) {
      yield put(companySuccess(res.data.company));
    }
  } catch (e) {
    yield put(signInError());
  }
}

export function* getMe({ payload }) {
  try {
    const res = yield call(api.get, '/me');

    if (!res.data.company) {
      yield put(signInError());
      toast.error('Esse usuario não pode acessar a dashboard...');
    } else {
      yield put(companySuccess(res.data.company));
      yield put(signInSuccess({ token: payload, user: res.data.user }));
    }
  } catch (e) {
    yield put(signInError());
    toast.error('Erro usuario ou senha incorretos...');
  }
}

export function* signIn(payload) {
  try {
    const res = yield call(api.post, 'me', payload.data);

    if (res.data.user.role !== 'admin') {
      if (res.data.user.tasker === false) {
        api.defaults.headers.Authorization = `Bearer ${res.data.token}`;

        yield put(companyRequest(res.data.token));
      } else {
        yield put(signInError());
        toast.error('Esse usuario não pode acessar a dashboard...');
      }
    } else {
      api.defaults.headers.Authorization = `Bearer ${res.data.token}`;
      yield put(signInSuccess(res.data));
    }
  } catch (e) {
    console.log(e);
    yield put(signInError());
    if (e.response.data && e.response.data.errors) {
      toast.error(e.response.data.errors[0]);
    } else {
      toast.error('Erro usuario ou senha incorretos...');
    }
  }
}

export function setToken({ payload }) {
  if (!payload || !payload.auth) return;

  const { token } = payload.auth;

  if (token) api.defaults.headers.Authorization = `Bearer ${token}`;
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGNIN_REQUEST', signIn),
  takeLatest('@auth/COMPANY_REQUEST', getMe),
  takeLatest('@auth/GET_ME_REQUEST', getMeUser),
]);

import React from 'react';

import {
  RowHeader,
  Col,
  HeaderText,
} from '../../../Dashboard/Users/UserTable/styles';

function TableHeader({ finalized, company }) {
  return (
    <RowHeader>
      {finalized && (
        <Col size={15}>
          <HeaderText>Status:</HeaderText>
        </Col>
      )}
      {!company && !finalized && (
        <Col size={15}>
          <HeaderText>Empresa:</HeaderText>
        </Col>
      )}

      <Col size={25}>
        <HeaderText>Descrição:</HeaderText>
      </Col>
      <Col size={finalized ? 15 : 20}>
        <HeaderText>Data</HeaderText>
      </Col>
      <Col size={20}>
        <HeaderText>Horário</HeaderText>
      </Col>
      <Col size={finalized ? 10 : 20}>
        <HeaderText>Recompensa</HeaderText>
      </Col>
    </RowHeader>
  );
}

export default TableHeader;

import React, { useRef, useState } from 'react';

import { format } from 'date-fns';

import Calendar from '~/assets/svg/calendar.svg';
import Check from '~/assets/svg/checkmark-circle-2.svg';
import Close from '~/assets/svg/close.svg';
import Dolar from '~/assets/svg/dolar.svg';
import MenuVertical from '~/assets/svg/more-verticalwhite.svg';
import { LoaderComponent } from '~/components/Button/styles';
import { Avatar } from '~/components/Menu/Profile/styles';
import ModalDelete from '~/components/Modals/ModalDelete';
import api from '~/services/api';
import masks from '~/utils/masks';

import {
  Container,
  Row,
  RowHeader,
  Col,
  ContentRow,
  HeaderText,
  NameLabel,
  BodyEmpty,
  SubLabel,
  SubLabelDate,
  TextEmpty,
  EditVertical,
  IconImage,
  EditIcon,
  BlankIcon,
  RowAction,
  DeleteIcon,
} from '../../Users/UserTable/styles';
import { Status, StatusContainer } from './styles';

const TableList = ({ data, loading, reflesh }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditIndex, setOpenEditIndex] = useState();
  const [selected, setSelected] = useState();
  const [approve, setApprove] = useState();

  const modalRefApprove = useRef(null);

  const handleOpenModalDelete = (data = {}) => {
    modalRefApprove.current.openModal(data);
  };

  const handleSubmitAprove = async (data) => {
    try {
      await api.put(`/finance/withdraw/${selected._id}/accept`, {
        status: approve ? 'd_1-executed' : 'd_1-canceled',
      });

      reflesh();
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const getStatus = (status) => {
    if (status === 'w_1-pending') return 'Pendente';
    if (status === 'd_1-executed') return 'Aprovado';
    if (status === 'd_1-canceled') return 'Reprovado';
  };

  return (
    <Container>
      <RowHeader>
        <Col size={50}>
          <HeaderText>Descrição:</HeaderText>
        </Col>
        <Col size={15}>
          <HeaderText>Data:</HeaderText>
        </Col>
        <Col size={20}>
          <HeaderText>Saque:</HeaderText>
        </Col>
        <Col size={15} />
      </RowHeader>

      {!loading && data.data.length === 0 && (
        <BodyEmpty>
          <TextEmpty>Nenhum dado encontrado!</TextEmpty>
        </BodyEmpty>
      )}

      {loading && (
        <LoaderComponent
          type="ThreeDots"
          color="#37465b"
          height={100}
          width={100}
          // 3 secs
        />
      )}

      {!loading &&
        data.data.map((element, index) => (
          <ContentRow>
            <Col size={15}>
              <Row>
                <StatusContainer status={element.status}>
                  <Status status={element.status}>
                    {getStatus(element.status)}
                  </Status>
                </StatusContainer>
              </Row>
            </Col>
            <Col size={35}>
              <Row>
                <Avatar
                  src={element.tasker_id && element.tasker_id.profile_photo}
                />
                <NameLabel half>
                  {element.tasker_id && element.tasker_id.name}
                </NameLabel>
              </Row>
            </Col>

            <Col size={15}>
              <Row>
                <img src={Calendar} />
                <SubLabelDate>
                  {format(new Date(element.createdAt), 'dd/MM/yyyy')}
                </SubLabelDate>
              </Row>
            </Col>
            <Col size={20}>
              <Row>
                <img src={Dolar} />
                <SubLabel>R$ {masks.currency(element.value)}</SubLabel>
              </Row>
            </Col>
            {element.status === 'w_1-pending' && (
              <Col noMargin size={15}>
                {openEdit && openEditIndex === index ? (
                  <RowAction margin>
                    <EditIcon
                      onClick={() => {
                        setSelected(element);
                        setApprove(true);
                        handleOpenModalDelete(element);
                      }}
                    >
                      <IconImage src={Check} />
                    </EditIcon>
                    <DeleteIcon
                      onClick={() => {
                        setSelected(element);
                        setApprove(false);

                        handleOpenModalDelete(element);
                      }}
                    >
                      <IconImage src={Close} />
                    </DeleteIcon>
                  </RowAction>
                ) : (
                  <RowAction>
                    <BlankIcon />
                    <EditVertical
                      onClick={() => {
                        setOpenEdit(true);
                        setOpenEditIndex(index);
                      }}
                    >
                      <IconImage fill="white" src={MenuVertical} />
                    </EditVertical>
                  </RowAction>
                )}
              </Col>
            )}
          </ContentRow>
        ))}

      <ModalDelete
        ref={modalRefApprove}
        message={
          approve
            ? `Tem certeza que deseja liberar o saldo nesse valor de R$ ${masks.currency(
                selected.value
              )}`
            : 'Tem certeza que deseja cancelar essa solicitação ? '
        }
        onSave={handleSubmitAprove}
      />
    </Container>
  );
};

export default TableList;

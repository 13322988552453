import React, { useEffect } from 'react';

import { withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const RadioRed = withStyles({
  root: {
    '&$checked': {
      color: '#ffbc00',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButtonsGroup({
  options,
  handleSelect,
  defaultValue,
}) {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    handleSelect(event.target.value);
  };

  useEffect(() => {
    if (defaultValue) {
      handleSelect(defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormControl component="fieldset">
      <RadioGroup row name="gender1" value={value} onChange={handleChange}>
        {options.map((element) => (
          <FormControlLabel
            value={element.value}
            control={<RadioRed />}
            label={element.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

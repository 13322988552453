import React, { useEffect, useState } from 'react';

import Pagination from '~/components/Pagination';
import {
  Content,
  FooterPagination,
  TabsStyle,
  TabStyle,
} from '~/pages/Dashboard/Users/styles';
import api from '~/services/api';

import MissionTable from './MissionTable';
import { Container, Title, Subtitle } from './styles';

const INITIAL_DATA = {
  data: [],
  page: 1,
  perPage: 8,
  totalPages: 1,
};
const PendingMissions = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState(0);

  const getData = async (page = 1) => {
    try {
      setLoading(true);

      const query = {
        perPage: 6,
        page,
        reviewed: true,
        status: 'f_1-finished',
      };

      if (value === 0) {
        query.conflit = 'pending';
      } else if (value === 1) {
        query.conflit = 'finalized';
      }

      const response = await api.get('/mission', {
        params: query,
      });

      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [value]);

  const handleChangePage = (page) => getData(page);

  return (
    <Container>
      <Title>Pendências e conflitos</Title>
      <Subtitle>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
        mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
        Vestibulum interdum, augue et euismod tincidunt.
      </Subtitle>

      <TabsStyle>
        <TabStyle selected={value === 0} onClick={() => setValue(0)}>
          Pendentes
        </TabStyle>
        <TabStyle selected={value === 1} onClick={() => setValue(1)}>
          Finalizadas
        </TabStyle>
      </TabsStyle>
      <Content>
        {value === 0 && (
          <MissionTable reflesh={getData} data={data} loading={loading} />
        )}
        {value === 1 && <MissionTable data={data} loading={loading} />}
      </Content>
      <FooterPagination>
        <Pagination
          onChange={handleChangePage}
          page={data.page}
          pageSize={data.limit}
          total={data.totalData}
          totalPages={data.totalPages}
          position="flex-end"
        />
      </FooterPagination>
    </Container>
  );
};

export default PendingMissions;

import React, { useEffect, useState } from 'react';

import Pagination from '~/components/Pagination';
import api from '~/services/api';

import CompanyTable from './CompanyTable';
import {
  Container,
  TabsStyle,
  TabStyle,
  Content,
  Title,
  Subtitle,
  FooterPagination,
} from './styles';
import UserTable from './UserTable';

const INITIAL_DATA = {
  data: [],
  page: 1,
  perPage: 8,
  totalPages: 1,
};

const Profile = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState(0);

  const getData = async (page = 1) => {
    try {
      setLoading(true);

      const response = await api.get(value === 0 ? '/user' : '/company', {
        params: {
          perPage: 6,
          page,
          tesker: true,
        },
      });

      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [value]);

  const handleChangePage = (page) => getData(page);

  return (
    <Container>
      <Title>Meus clientes</Title>
      <Subtitle>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
        mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
        Vestibulum interdum, augue et euismod tincidunt, elit urna tempus
        mauris.
      </Subtitle>
      <TabsStyle>
        <TabStyle selected={value === 0} onClick={() => setValue(0)}>
          Teskers
        </TabStyle>
        <TabStyle selected={value === 1} onClick={() => setValue(1)}>
          Empresas
        </TabStyle>
      </TabsStyle>
      <Content>
        {value === 0 ? (
          <UserTable data={data} loading={loading} reflesh={getData} />
        ) : (
          <CompanyTable data={data} loading={loading} reflesh={getData} />
        )}
      </Content>
      <FooterPagination>
        <Pagination
          onChange={handleChangePage}
          page={data.page}
          pageSize={data.limit}
          total={data.totalData}
          totalPages={data.totalPages}
          position="flex-end"
        />
      </FooterPagination>
    </Container>
  );
};

export default Profile;

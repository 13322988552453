import styled, { css, keyframes } from 'styled-components';
import devices from '~/styles/configs/devices';

export const MessageModal = styled.span`
  font-size: 18px;
  text-align: center;
  color: #212121;
  margin-top: 50px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Left = styled.div`
  margin-left: 25px;
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: 800;
`;

export const Subtitle = styled.h1`
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
`;

export const ButtonClose = styled.div`
  @media ${devices.laptop} {
    width: 32px;
    height: 32px;
  }
  border-radius: 19px;
  margin-left: 15px;
  background-color: #ffffff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const HeaderModal = styled.div`
  display: flex;

  height: 122px;
  background-color: #37465b;
  border-bottom-left-radius: 25px;
`;

import styled, { css, keyframes } from 'styled-components';

import fadeIn from 'react-animations/lib/fade-in';

import ripple from '~/styles/animations/ripple';
import Loader from 'react-loader-spinner';
import { Button } from '@material-ui/core';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled(Button)`
  ${ripple};
  animation: 800ms ${fadeInAnimation};

  -webkit-appearance: none;

  text-align: center;
  font-weight: 500;

  height: 40px;

  padding: 8px 12px;

  transition: background-color 200ms linear, box-shadow 200ms linear,
    border-color 200ms linear;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
    
  &:disabled {
    cursor: not-allowed;
  }

  &.default {
    color: #707070;
    border: 1px solid #eeeeee;
    background-color: transparent;

    &:not(:disabled):hover {
      color: #1c1c1e;
      border-color: #bdbdbd;
      background-color: #fafafa;
    }
  }

  &.outline-dark {
    color: #37465b;
    border: none;
    background-color: #fff;
    border: solid 2px #37465b;

    &:not(:disabled):hover {
      border-color: #37465b;
      box-shadow: 0 6px 12px #00000020;
    }
  }

  &.dark {
    color: #ffbc00;
    border: none;
    background-color: #37465b;
    border: solid 2px #37465b;

    &:not(:disabled):hover {
      border-color: #37465b;
      box-shadow: 0 6px 12px #00000020;
      background-color: #37465b;
    }
  }
  &.darkYellow {
    color: #37465b;
    border: none;
    background-color: #ffbc00;
    border: solid 2px #ffbc00;

    &:not(:disabled):hover {
      border-color: #ffbc00;
      box-shadow: 0 6px 12px #00000020;
      background-color: #ffbc00;
    }
  }
  &.inline {
    color: #ffc107;
    border: none;
    background-color: #fff;
  }

  &.inlineDark {
    color: #37465b;
    border: none;
    background-color: #fff;
  }

  &.action {
    color: #ffffff;
    border: none;
    background-color: #ffc107;

    &:not(:disabled):hover {
      background-color: #b08504;
      box-shadow: 0 6px 12px #00000020;
    }
  }

  &.invert {
    color: #ffc107;
    border: none;
    background-color: transparent;

    &:not(:disabled):hover {
      color: #b08504;
    }
  }

  &.invert-border {
    color: #ffc107;
    border: 1px solid #ffc107;
    background-color: transparent;

    &:not(:disabled):hover {
      color: #ffffff;
      background-color: #ffc107;
    }
  }

  &.danger {
    color: #ffffff;
    border: none;
    background-color: #ff3b30;

    &:not(:disabled):hover {
      background-color: #ff3b30;
    }
  }
  ${({ round }) =>
    round ? `border-radius: 50px` : 'border-radius: 3px'} !important;
`;

export const LoaderComponent = styled(Loader)`
  margin-bottom: 0px;
  margin: auto;
  svg {
    margin-bottom: 0px !important;
  }
`;

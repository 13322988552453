import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~/components/Button';
import { LoaderComponent } from '~/components/Button/styles';
import Percent from '~/components/Charts/Percent';

import { BoxLabel, LabelName, Labels } from '../ClientesChart/styles';
import { Chart, PaperContent, Row, TitleInner, TextChart } from './styles';

const MissoesChart = ({ loading, data }) => {
  const [pending, setPending] = useState();
  const [accepted, setAccepted] = useState();
  const [finished, setFinished] = useState();
  const [ongoing, setOngoing] = useState();

  const history = useHistory();

  const [loadingInner, setLoadingInner] = useState(true);

  useEffect(() => {
    if (data && data.mission) {
      data.mission.forEach((element) => {
        if (element._id === 'w_1-pending') {
          setPending(Math.round((element.total / data.totalMission) * 100));
        } else if (element._id === 'd_1-accepted') {
          setAccepted(Math.round((element.total / data.totalMission) * 100));
        } else if (element._id === 'd_2-doing') {
          setOngoing(Math.round((element.total / data.totalMission) * 100));
        } else if (element._id === 'f_1-finished') {
          setFinished(Math.round((element.total / data.totalMission) * 100));
        }
      });

      setLoadingInner(false);
    }
  }, [data]);

  return (
    <PaperContent>
      <TitleInner>Missões</TitleInner>

      <Labels full>
        <Row>
          <Row>
            <BoxLabel radius={4} color="#ffbc00" />
            <LabelName>Cadastradas</LabelName>
          </Row>
          <Row>
            <BoxLabel radius={4} color="#16ab6b" />

            <LabelName>Abertas</LabelName>
          </Row>
          <Row>
            <BoxLabel radius={4} color="#2e7dff" />

            <LabelName>Em andamento</LabelName>
          </Row>
          <Row>
            <BoxLabel radius={4} color="#ef4747" />

            <LabelName>Fechadas</LabelName>
          </Row>
        </Row>
      </Labels>

      {!loading && !loadingInner ? (
        <Row>
          <Chart>
            <Percent width="180" color="#ffbc00" data={pending || 0} />
          </Chart>
          <Chart>
            <Percent width="180" color="#16ab6b" data={accepted || 0} />
          </Chart>
          <Chart>
            <Percent width="180" color="#2e7dff" data={ongoing || 0} />
          </Chart>
          <Chart>
            <Percent width="180" color="#ef4747" data={finished || 0} />
          </Chart>
        </Row>
      ) : (
        <LoaderComponent
          type="ThreeDots"
          color="#ffc107"
          height={20}
          width={50}
          //3 secs
        />
      )}

      <TextChart>
        Every large design company whether it’s a multi-national branding
        corporation or a regular down at heel tatty magazine publisher needs to
        fill holes in the workforce.
      </TextChart>

      <Button
        onClick={() => history.push('/mission')}
        label="Ver todas missões"
        color="inline"
      />
    </PaperContent>
  );
};

export default MissoesChart;

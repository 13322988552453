import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;

  display: flex;
  flex-wrap: wrap;
`;

export const Head = styled.div`
  grid-column: 1fr;

  @media ${devices.laptop} {
    grid-column: span 5;
  }
`;

export const SolicitationContent = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

export const Obscontainer = styled.div`
  border-radius: 8px;
  background-color: #f2f3f4;
  margin-top: 15px;
`;

export const ObsContent = styled.div`
  padding: 15px;
`;

export const PhotoContent = styled.div`
  margin-top: 15px;
  img {
    width: 66px;
    height: 66px;
  }
`;

export const StatusContainer = styled.div`
  background-color: ${({ status }) => {
    return !status ? '#ef4747' : '#16ab6b';
  }};
  margin-left: 5px;
  border-radius: 50px;
`;

export const Status = styled.div`
  color: white;
  padding: 5px;
`;

export const SolicitationBy = styled.div``;
export const LabelSolicitationBy = styled.div`
  color: ${({ color }) => color || '#666666'};
  font-size: 13px;
`;

export const Col = styled.div`
  width: ${({ size }) => size}%;

  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    border-radius: 50px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #37465b;
  margin-top: 15px;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
`;

export const TitleWhite = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #ffffff;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
`;

export const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #666666;
  width: 70%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-right-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 15px;
  padding: 25px;
`;
export const HeaderProfile = styled.div`
  height: 160px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  background: transparent;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
`;

export const ContentInfo = styled.div`
  padding: 0px 0px 0px 25px;
  text-align: left;
`;

export const ButtonClose = styled.div`
  @media ${devices.laptop} {
    width: 32px;
    height: 32px;
  }
  border-radius: 19px;
  margin-left: 15px;
  background-color: #ffffff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 15px;
`;

export const FooterContainer = styled.div`
  padding: 25px;
  display: flex;
  justify-content: flex-start;
`;

export const ContainerDetails = styled.div`
  border-radius: 8px;
  background-color: #f2f3f4;
  .contentDetails {
    padding: 10px 25px;

    .row-img {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
`;

export const ContentBody = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  border-radius-top-right: 15px;
  border-radius-top-left: 15px;

  .profile {
    padding: 15px 25px;
    width: 60%;
    position: relative;
  }

  .profileinfo {
    padding: 15px 25px;

    margin-top: 15px;
    width: 40%;
  }
`;

export const Content = styled.div`
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
`;

export const ProfilePhoto = styled.div`
  height: 95px;
  width: 95px;

  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
  display: flex;

  border: 1px solid #ffbc00;
  border-radius: 100px;
  img {
    height: 90px;
    width: 90px;
    border-radius: 100px;
  }
`;

export const LabelTitle = styled.h1`
  font-size: 17px;
  color: #273645;
  margin-top: 15px;
`;

export const LabelValue = styled.h1`
  font-size: 15px;
  font-weight: normal;
  margin: 10px 0px;
  color: #757f88;
`;
export const LabelBlack = styled.h1`
  font-size: 15px;
  font-weight: normal;
  margin: 10px 0px;
  color: #273645;
`;

export const LabelWhite = styled.h1`
  font-size: 15px;
  font-weight: normal;
  margin: 10px 0px;
  color: #ffffff;
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
`;

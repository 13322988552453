/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';

import { Container } from './styles';

const ImageUpload = ({ onChange, children }) => {
  const ref = useRef(null);

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <Container onClick={() => ref.current.click()}>
      <>
        {children}
        <input
          accept="image/*"
          hidden
          ref={ref}
          onChange={(e) => handleChange(e)}
          type="file"
        />
      </>
    </Container>
  );
};

export default ImageUpload;

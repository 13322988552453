import React, { useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';

import Profile from '~/assets/img/profile.png';
import Calendar from '~/assets/svg/calendar.svg';
import Edit from '~/assets/svg/edit-2.svg';
import Pin from '~/assets/svg/map-pin.svg';
import MenuVertical from '~/assets/svg/more-verticalwhite.svg';
import Trash from '~/assets/svg/trash-2.svg';
import { LoaderComponent } from '~/components/Button/styles';
import { Avatar } from '~/components/Menu/Profile/styles';
import ModalDelete from '~/components/Modals/ModalDelete';
import ModalEditDrawer from '~/components/Modals/ModalEditDrawer';
import api from '~/services/api';

import {
  Container,
  Row,
  RowHeader,
  Col,
  ContentRow,
  HeaderText,
  NameLabel,
  BodyEmpty,
  SubLabel,
  TextEmpty,
  BlankIcon,
  DeleteIcon,
  IconImage,
  EditVertical,
  EditIcon,
  RowAction,
  SubLabelDate,
} from '../UserTable/styles';
import EditForm from './EditForm';

const UserTable = ({ data, loading, reflesh }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditIndex, setOpenEditIndex] = useState();

  const [selected, setSelected] = useState();

  const modalRef = useRef(null);

  const modalRefEdit = useRef(null);

  const handleOpenModalEdit = (data = {}) => {
    setSelected(data);
    modalRefEdit.current.openModal(data);
  };

  const handleOpenModalDelete = (data = {}) => {
    modalRef.current.openModal(data);
  };

  const handleDelete = async (data) => {
    try {
      await api.delete(`/company/${data._id}`);
    } catch (err) {
    } finally {
      reflesh();
    }
  };

  useEffect(() => {}, []);
  return (
    <Container>
      <RowHeader>
        <Col size={35}>
          <HeaderText>Nome:</HeaderText>
        </Col>
        <Col size={15}>
          <HeaderText>Data de cadastro</HeaderText>
        </Col>
        <Col size={15}>
          <HeaderText>Estado</HeaderText>
        </Col>
        <Col size={20}>
          <HeaderText>Cidade</HeaderText>
        </Col>
        <Col size={15} />
      </RowHeader>

      {!loading && data.data.length === 0 && (
        <BodyEmpty>
          <TextEmpty>Nenhum dado encontrado!</TextEmpty>
        </BodyEmpty>
      )}

      {loading && (
        <LoaderComponent
          type="ThreeDots"
          color="#37465b"
          height={100}
          width={100}
          // 3 secs
        />
      )}

      {!loading &&
        data.data.map((element, index) => (
          <ContentRow>
            <Col size={35}>
              <Row
                className="show-profile"
                onClick={() => handleOpenModalEdit(element)}
              >
                <Avatar src={element.assets ? element.assets.logo : Profile} />
                <NameLabel half>{element.name}</NameLabel>
              </Row>
            </Col>

            <Col size={15}>
              <Row>
                <img src={Calendar} />
                <SubLabelDate>
                  {format(new Date(element.createdAt), 'dd/MM/yyyy')}
                </SubLabelDate>
              </Row>
            </Col>
            <Col size={15}>
              <Row>
                {element.address ? (
                  <>
                    <img src={Pin} />
                    <SubLabel>{element.address.state}</SubLabel>{' '}
                  </>
                ) : (
                  '-'
                )}
              </Row>
            </Col>
            <Col size={20}>
              <Row>
                {element.address ? (
                  <>
                    <img src={Pin} />
                    <SubLabel>{element.address.city}</SubLabel>{' '}
                  </>
                ) : (
                  '-'
                )}
              </Row>
            </Col>
            <Col noMargin size={15}>
              {openEdit && openEditIndex === index ? (
                <RowAction margin>
                  <EditIcon
                    onClick={() => {
                      handleOpenModalEdit(element);
                    }}
                  >
                    <IconImage src={Edit} />
                  </EditIcon>

                  <DeleteIcon
                    onClick={() => {
                      handleOpenModalDelete(element);
                    }}
                  >
                    <IconImage src={Trash} />
                  </DeleteIcon>
                </RowAction>
              ) : (
                <RowAction>
                  <BlankIcon />
                  <EditVertical
                    onClick={() => {
                      setOpenEdit(true);
                      setOpenEditIndex(index);
                    }}
                  >
                    <IconImage src={MenuVertical} />
                  </EditVertical>
                </RowAction>
              )}
            </Col>
          </ContentRow>
        ))}

      <ModalDelete
        ref={modalRef}
        message="Tem certeza que deseja remover essa empresa ? "
        onSave={handleDelete}
      />
      <ModalEditDrawer
        title="Visualizar perfil"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas."
        ref={modalRefEdit}
        url="company"
        onSave={reflesh}
      >
        <EditForm data={selected} refModal={modalRefEdit} />
      </ModalEditDrawer>
    </Container>
  );
};

export default UserTable;

import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
  ${({ full }) => full && 'width: 100%;'}
`;

export const LabelName = styled.div`
  color: #9ca3aa;
  font-size: 13px;
  font-weight: 600;
`;

export const BoxLabel = styled.div`
  width: 20px;
  height: 20px;
  margin: 5px 5px;
  border-radius: ${({ radius }) => `${radius}px;`}
    ${({ color }) => `background-color: ${color};`};
`;

import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StatusContainer = styled.div`
  padding: 15px;
`;

export const Status = styled.div`
  padding: 1px 10px;
  border-radius: 20px;

  border: solid 1px ${({ status }) => status === 'w_1-pending' && '#ff9e00'};
  border: solid 1px ${({ status }) => status === 'd_1-executed' && '#16ab6b'};
  border: solid 1px ${({ status }) => status === 'd_1-canceled' && '#ef4747'};

  color: ${({ status }) => status === 'w_1-pending' && '#ff9e00'};
  color: ${({ status }) => status === 'd_1-executed' && '#16ab6b'};
  color: ${({ status }) => status === 'd_1-canceled' && '#ef4747'};
`;

import React, { useEffect, useRef, useState } from 'react';

import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  setHours,
  setMinutes,
} from 'date-fns';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Calendar from '~/assets/svg/calendar.svg';
import Check from '~/assets/svg/checkmark-circle-2.svg';
import Clock from '~/assets/svg/clock.svg';
import Close from '~/assets/svg/close.svg';
import Dolar from '~/assets/svg/dolar.svg';
import Edit from '~/assets/svg/edit-2.svg';
import MenuVertical from '~/assets/svg/more-verticalwhite.svg';
import Trash from '~/assets/svg/trash-2.svg';
import { LoaderComponent } from '~/components/Button/styles';
import ModalDelete from '~/components/Modals/ModalDelete';
import ModalDisapprove from '~/components/Modals/ModalDisapprove';
import ModalEditDrawer from '~/components/Modals/ModalEditDrawer';
import api from '~/services/api';
import { convertTime } from '~/utils/convert';
import masks from '~/utils/masks';
import utcDate from '~/utils/utcDate';
import { createSchema } from '~/validators/mission.schema';

import {
  Container,
  Row,
  Col,
  ContentRow,
  NameLabel,
  BodyEmpty,
  EditIcon,
  SubLabel,
  TextEmpty,
  RowAction,
  IconImage,
  BlankIcon,
  DeleteIcon,
  EditVertical,
  ExpiredLabel,
  SubLabelDate,
} from '../../../Dashboard/Users/UserTable/styles';
import CreateMission from '../CreateMission';
import TableHeader from '../TableHeader';
import { ContentStatus, SubLabelStatus, MissionDetails } from './styles';

const MissionTable = ({
  data,
  finished,
  loading,
  canEdit,
  canAprove,
  reflesh,
}) => {
  useEffect(() => {}, []);

  const modalRef = useRef(null);

  const history = useHistory();

  const company = useSelector((state) => state.auth.company);

  const [openEdit, setOpenEdit] = useState(false);
  const [openEditIndex, setOpenEditIndex] = useState();
  const [selected, setSelected] = useState();
  const [approve, setApprove] = useState();
  const [extraData, setExtraData] = useState({});

  const modalRefEdit = useRef(null);

  const modalRefApprove = useRef(null);

  const handleOpenModalDelete = (data = {}) => {
    modalRef.current.openModal(data);
  };

  const handleOpenModalEdit = (data = {}) => {
    setSelected(data);

    setExtraData({
      checklist: data.checklist,
      company: data.company,
      turn: data.turn,
    });
    modalRefEdit.current.openModal(data);
  };

  const handleOpenModalApprove = (data = {}, aprove) => {
    modalRefApprove.current.openModal(data);
    setApprove(aprove);
    setSelected(data);
  };

  const handleSubmitAprove = async (data) => {
    try {
      const review = {
        status: approve ? 'approved' : 'disapproved',
        date: new Date(),
      };

      await api.put(`mission/${data._id}/finish`, { review });

      reflesh();
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleDelete = async (data) => {
    try {
      await api.delete(`/mission/${data._id}`);
    } catch (err) {
    } finally {
      reflesh();
    }
  };

  const handleStatus = (item) => {
    if (item.status === 'f_1-finished' && item.review) {
      return true;
    }
    return false;
  };

  const handleStatusWaitingReview = (item) => {
    if (item.status === 'f_1-finished' && !item.review) {
      return true;
    }
  };

  const checkExpired = ({ executionDate, executionTime, tasker, status }) => {
    if (tasker) return false;

    if (status !== 'w_1-pending') return false;

    if (isSameDay(new Date(), new Date(executionDate.end))) {
      let dateTime = new Date();

      dateTime = setHours(dateTime, format(new Date(executionTime.end), 'HH'));
      dateTime = setMinutes(
        dateTime,
        format(new Date(executionTime.end), 'mm')
      );
      if (new Date() > dateTime) {
        return true;
      }
    } else if (utcDate(new Date()) > utcDate(new Date(executionDate.end))) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <TableHeader finalized={finished || canAprove} company={company} />

      {!loading && data.data.length === 0 && (
        <BodyEmpty>
          <TextEmpty>Nenhum dado encontrado!</TextEmpty>
        </BodyEmpty>
      )}

      {loading && (
        <LoaderComponent
          type="ThreeDots"
          color="#37465b"
          height={100}
          width={100}
          // 3 secs
        />
      )}

      {!loading &&
        data.data.map((element, index) => (
          <ContentRow>
            {canAprove && (
              <Col size={15}>
                <ContentStatus statusString={element.status}>
                  <SubLabelStatus statusString={element.status}>
                    {handleStatusWaitingReview(element) &&
                      'Aguardando validação'}
                    {element.status === 'd_2-doing' && 'Em Andamento'}
                    {element.status === 'd_1-accepted' && 'Aceito'}
                  </SubLabelStatus>
                </ContentStatus>
              </Col>
            )}
            {handleStatus(element) && (
              <Col size={15}>
                <ContentStatus status={element.review.status}>
                  <SubLabelStatus status={element.review.status}>
                    {element.review.status === 'approved'
                      ? 'Aprovada'
                      : 'Reprovada'}
                  </SubLabelStatus>
                </ContentStatus>
              </Col>
            )}
            {!company && !(finished || canAprove) && (
              <Col size={15}>
                <NameLabel>{element.company.name}</NameLabel>
              </Col>
            )}
            <Col size={25}>
              <MissionDetails
                onClick={() =>
                  checkExpired(element)
                    ? handleOpenModalEdit(element)
                    : history.push(`mission/${element._id}`)
                }
              >
                <NameLabel>{element.name}</NameLabel>
                <SubLabel full>{element.description}</SubLabel>
              </MissionDetails>
            </Col>

            <Col size={finished || canAprove ? 15 : 20}>
              <Row>
                <img src={Calendar} />
                <div>
                  <SubLabel>
                    {format(
                      new Date(element.executionDate.start),
                      'dd/MM/yyyy'
                    )}
                    -{' '}
                    {format(new Date(element.executionDate.end), 'dd/MM/yyyy')}
                    {checkExpired(element) && (
                      <ExpiredLabel>Expirado!</ExpiredLabel>
                    )}
                  </SubLabel>
                </div>
              </Row>
            </Col>
            <Col size={20}>
              <Row>
                {element.executionTime ? (
                  <>
                    <img src={Clock} />
                    <SubLabelDate>
                      Entre{' '}
                      {format(new Date(element.executionTime.start), 'HH:mm')} e{' '}
                      {format(new Date(element.executionTime.end), 'HH:mm')}{' '}
                    </SubLabelDate>
                  </>
                ) : (
                  '-'
                )}
              </Row>
            </Col>
            <Col size={finished || canAprove ? 10 : 20}>
              <Row>
                <img src={Dolar} />
                <SubLabel>R$ {masks.currency(element.priceReward)}</SubLabel>
              </Row>
            </Col>
            <Col noMargin size={15}>
              {canEdit && company && (
                <>
                  {openEdit && openEditIndex === index ? (
                    <RowAction margin>
                      <EditIcon
                        disabled={element.tasker}
                        onClick={() => {
                          !element.tasker && handleOpenModalEdit(element);
                        }}
                      >
                        <IconImage src={Edit} />
                      </EditIcon>
                      <DeleteIcon
                        onClick={() => {
                          handleOpenModalDelete(element);
                        }}
                      >
                        <IconImage src={Trash} />
                      </DeleteIcon>
                    </RowAction>
                  ) : (
                    <RowAction>
                      <BlankIcon />
                      <EditVertical
                        onClick={() => {
                          setOpenEdit(true);
                          setOpenEditIndex(index);
                        }}
                      >
                        <IconImage fill="white" src={MenuVertical} />
                      </EditVertical>
                    </RowAction>
                  )}
                </>
              )}
              {canAprove && element.status === 'f_1-finished' && (
                <>
                  {openEdit && openEditIndex === index ? (
                    <RowAction margin>
                      <EditIcon
                        onClick={() => {
                          handleOpenModalApprove(element, false);
                        }}
                      >
                        <IconImage src={Close} />
                      </EditIcon>
                      <DeleteIcon
                        onClick={() => {
                          handleOpenModalApprove(element, true);
                        }}
                      >
                        <IconImage src={Check} />
                      </DeleteIcon>
                    </RowAction>
                  ) : (
                    <RowAction>
                      <BlankIcon />
                      <EditVertical
                        onClick={() => {
                          setOpenEdit(true);
                          setOpenEditIndex(index);
                        }}
                      >
                        <IconImage fill="white" src={MenuVertical} />
                      </EditVertical>
                    </RowAction>
                  )}
                </>
              )}
            </Col>
          </ContentRow>
        ))}
      <ModalDelete
        ref={modalRef}
        message="Tem certeza que deseja remover essa missão ? "
        onSave={handleDelete}
      />
      <ModalDisapprove
        ref={modalRefApprove}
        approve={approve}
        message={
          approve
            ? `Tem certeza que deseja aprovar
            essa missão? Você irá liberar o valor de R$ ${masks.currency(
              selected.priceReward - selected.tax_fee
            )}`
            : 'Tem certeza que deseja reprovar essa missão ? '
        }
        onSave={handleSubmitAprove}
      />
      <ModalEditDrawer
        title="Editar missão"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas."
        ref={modalRefEdit}
        onSave={reflesh}
        extraData={extraData}
        validation={createSchema}
        url="mission"
      >
        <CreateMission
          extraData={{ checklist: extraData.checklist, turn: extraData.turn }}
          setExtraData={setExtraData}
        />
      </ModalEditDrawer>
    </Container>
  );
};

export default MissionTable;

import axios from 'axios';

const {
  REACT_APP_API_URL,
  REACT_APP_API_URL_LOCAL,
  REACT_APP_API_URL_PROD,
} = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export default api;

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '~/services/api';
import masks from '~/utils/masks';

import { Container, Row, Col, Title, Subtitle } from './styles';

const options = [
  { value: 0, label: 'Janeiro' },
  { value: 1, label: 'Fevereiro' },
  { value: 2, label: 'Março' },
  { value: 3, label: 'Abril' },
  { value: 4, label: 'Maio' },
  { value: 5, label: 'Junho' },
  { value: 6, label: 'Julho' },
  { value: 7, label: 'Agosto' },
  { value: 8, label: 'Setembro' },
  { value: 9, label: 'Outubro' },
  { value: 10, label: 'Novembro' },
  { value: 11, label: 'Dezembro' },
];

const Statistics = ({ monthSelected, setmonthSelected }) => {
  const [totalBalance, setTotal] = useState(0);
  const [waitingTotal, setWaitingTotal] = useState(0);
  const [proveTotal, setProveTotal] = useState(0);

  const getStatistics = async () => {
    try {
      const { data } = await api.get('/finance/balance', {
        params: { month: monthSelected.value },
      });

      setTotal(0);
      setWaitingTotal(0);
      setProveTotal(0);
      data.forEach((element) => {
        if (
          element._id.type === 'payment' &&
          element._id.status === 'd_1-executed'
        ) {
          setTotal(element.total);
          setProveTotal(element.total);
        }
        if (
          element._id.type === 'payment' &&
          element._id.status === 'w_1-pending'
        ) {
          setWaitingTotal(element.total);
        }
      });
    } catch (er) {
      console.log(er);
    }
  };

  useEffect(() => {
    const datenow = new Date();

    setmonthSelected(
      options.find((element) => element.value === datenow.getMonth())
    );
  }, []);

  useEffect(() => {
    getStatistics();
  }, [monthSelected]);

  return (
    <Container>
      <Row>
        <Col size={30}>
          <div className="select">
            {monthSelected && (
              <Select
                defaultValue={monthSelected}
                isSearchable={false}
                options={options}
                onChange={(e) => setmonthSelected(e)}
              />
            )}
          </div>
        </Col>
        <Col size={20}>
          <Title>Total pago:</Title>
          <Subtitle>R$ {masks.currency(totalBalance + waitingTotal)}</Subtitle>
        </Col>
        <Col size={30}>
          <Title>Aguardando aprovação:</Title>
          <Subtitle>R$ {masks.currency(waitingTotal)}</Subtitle>
        </Col>
        <Col size={20}>
          <Title>Aprovadas:</Title>
          <Subtitle>R$ {masks.currency(proveTotal)}</Subtitle>
        </Col>
      </Row>
    </Container>
  );
};

export default Statistics;

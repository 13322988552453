import React from 'react';

import Hidden from '@material-ui/core/Hidden';

import ArrowForward from '~/assets/svg/arrow-forward.svg';

import Profile from './Profile';
import { Container, Navigation, MenuDrawer } from './styles';

const Menu = ({ open, setOpen }) => {
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Navigation>
        <Hidden mdUp implementation="css">
          <MenuDrawer onClick={handleDrawerToggle}>
            <img src={ArrowForward} alt="React Logo" />
          </MenuDrawer>
        </Hidden>
      </Navigation>
      <Profile />
    </Container>
  );
};

export default Menu;

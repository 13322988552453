import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProfileDefault from '~/assets/img/profile.png';
import Button from '~/components/Button';
import { LoaderComponent } from '~/components/Button/styles';
import { Label } from '~/pages/Dashboard/Profile/EditForm/styles';
import {
  ContentStatistics,
  TextGray,
} from '~/pages/Dashboard/Users/CompanyTable/EditForm/styles';
import api from '~/services/api';
import HelpContainer from '../components/HelpContainer';

import MissionCard from '../components/MissionCard';
import StatisticsContainer from '../components/StatisticsContainer';
import {
  Container,
  ContentCarousel,
  Title,
  Subtitle,
  Missions,
  Col,
  Content,
  CompanyName,
  ProfilePhoto,
  InnerContent,
  Row,
  LoadingContainer,
} from './styles';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [mission, setMission] = useState({
    data: [],
  });

  const [loadingPending, setLoadingPending] = useState(false);
  const [missionPending, setMissionPending] = useState({
    data: [],
  });

  const [review, setReview] = useState({});

  const [totalMission, setTotalMission] = useState(0);
  const [totalActive, setTotalActive] = useState(0);

  const company = useSelector((state) => state.auth.company);

  const history = useHistory();

  const getMissionPending = async () => {
    setLoadingPending(true);
    try {
      const query = {};

      query.status = 'f_1-finished';
      query.reviewed = false;
      query.perPage = 4;

      const { data } = await api.get(`/mission`, { params: query });
      setMissionPending(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPending(false);
    }
  };

  const getMission = async () => {
    setLoading(true);
    try {
      const query = {};

      query.status = 'w_1-pending';
      query.perPage = 8;

      const { data } = await api.get(`/mission`, { params: query });

      setMission(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getMe = async () => {
    try {
      const { data } = await api.get('/me');

      if (data.review.length) {
        setReview(data.review[0]);
      }
      setTotalMission(data.totalMission);
      setTotalActive(data.totalActive);
    } catch (err) {}
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    getMission();
    getMissionPending();
    getMe();
  }, []);

  const handlePercent = () => {
    if (review.count > 0) {
      const percent = (review.count * 5 - review.total) / review.total;
      return (100 - percent * 100).toFixed(0);
    }
    return 0;
  };

  return (
    <Container>
      <Col size={68}>
        <ContentCarousel>
          <Title>Olá {company.name}, veja suas missões para hoje</Title>
          <Subtitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
            mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
            Vestibulum interdum, augue et euismod tincidunt.
          </Subtitle>
          <Missions>
            {loading && (
              <LoadingContainer>
                <LoaderComponent
                  type="ThreeDots"
                  color="#fff"
                  height={20}
                  width={50}
                />
              </LoadingContainer>
            )}
            {!loading &&
              mission.data.map((element) => (
                <MissionCard color="white" item={element} />
              ))}

            {!loading && mission.data.length === 0 && (
              <LoadingContainer>
                <CompanyName>Nenhuma missão encontrada</CompanyName>
              </LoadingContainer>
            )}
          </Missions>
        </ContentCarousel>
        <StatisticsContainer />
        <HelpContainer />
      </Col>
      <Col size={32}>
        <Content>
          <ProfilePhoto>
            <img
              src={
                company.assets && company.assets.logo
                  ? `${company.assets.logo}?${new Date().getTime()}`
                  : ProfileDefault
              }
            />
          </ProfilePhoto>
          <InnerContent>
            <CompanyName fullWidth>{company.name}</CompanyName>
            <TextGray>Pontuação: {handlePercent()}/100</TextGray>
          </InnerContent>
          <InnerContent
            style={{
              borderRadius: 8,
              backgroundColor: '#ebecee',
            }}
          >
            <ContentStatistics>
              <Label style={{ textAlign: 'center' }}>Total</Label>
              <TextGray>{totalMission}</TextGray>
            </ContentStatistics>
            <ContentStatistics>
              <Label style={{ textAlign: 'center' }}>Ativas</Label>
              <TextGray>{totalMission - totalActive}</TextGray>
            </ContentStatistics>
            <ContentStatistics>
              <Label style={{ textAlign: 'center' }}>Finalizadas</Label>
              <TextGray>{totalActive}</TextGray>
            </ContentStatistics>
          </InnerContent>
          <Row>
            <CompanyName>Missões pendentes</CompanyName>
            <Button
              color="inline"
              onClick={() => history.push('/company/mission')}
              label="Ver todas"
            />
          </Row>
          {loadingPending && (
            <LoadingContainer>
              <LoaderComponent
                type="ThreeDots"
                color="#37465b"
                height={20}
                width={50}
              />
            </LoadingContainer>
          )}
          {!loading &&
            missionPending.data.map((element) => (
              <MissionCard fullWidth item={element} />
            ))}

          {!loading && missionPending.data.length === 0 && (
            <LoadingContainer>
              <CompanyName>Nenhuma missão encontrada</CompanyName>
            </LoadingContainer>
          )}
        </Content>
      </Col>
    </Container>
  );
};

export default Dashboard;

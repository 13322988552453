import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Public from '~/pages/_layouts/Public';

const LoginRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? (
          <Public>
            <Component {...props} />
          </Public>
        ) : (
          <>
            {user.role === 'admin' ? (
              <Redirect to="/" />
            ) : (
              <Redirect to="/company/mission" />
            )}
          </>
        )
      }
    />
  );
};

LoginRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default LoginRoute;

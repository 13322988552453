import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #37465b;

  height: 100%;
`;

export const TitleYellow = styled.h1`
  color: #ffbc00;
  margin: 5px 0px;
`;

export const SubtitleYellow = styled.h1`
  color: #ffffff;
  font-size: 18px;
  margin: 10px 0px;
`;

export const Logo = styled.img`
  margin-bottom: 25px;
  width: 250px;
  margin-top: 50px;

  @media ${devices.mobileL} {
    width: 400px;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 35px 0px;
  height: 100%;

  @media ${devices.mobileL} {
    max-width: 450px;
  }
  .root-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    svg {
      margin-bottom: 45px;
    }

    .root-input {
      margin-bottom: 15px;
    }

    .root-button {
      margin-top: 15px;
    }
  }
`;

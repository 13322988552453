import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { format, isAfter, isSameDay, subDays } from 'date-fns';
import { toast } from 'react-toastify';

import ArrowLeft from '~/assets/svg/arrow-left.svg';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Modal from '~/components/ModalDrawer';
import Separator from '~/components/Separator';
import useModal from '~/hooks/useModal';
import api from '~/services/api';
import { converDate, convertDates, convertTime } from '~/utils/convert';
import yupValidate from '~/utils/yupValidate';

import { Row, HeaderModal, ButtonClose, Title, Subtitle, Left } from './styles';

const ModalEditDrawer = (
  {
    title,
    subtitle,
    onSave,
    message,
    children,
    url,
    create,
    validation,
    extraData = {},
    ...rest
  },
  ref
) => {
  const [initialData, setInitialData] = useState({});

  const { isOpen, closeModal, openModal } = useModal();

  const [loading, setloading] = useState(false);

  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
    setValues: (data = {}) => {
      formRef.current.setData(data);
    },
  }));

  const handleSubmit = async (formData) => {
    setloading(true);
    try {
      formData = {
        ...formData,
        ...extraData,
      };

      if (
        formData.executionDate &&
        formData.executionTime &&
        formData.executionDate.start &&
        formData.executionDate.end &&
        formData.executionTime.end &&
        formData.executionTime.start
      ) {
        if (
          new Date(`${formData.executionDate.start}`) <= subDays(new Date(), 1)
        ) {
          return toast.error('Escolha uma data inicial válida');
        }

        if (
          isSameDay(
            new Date(converDate(formData.executionDate.start)),
            new Date(converDate(formData.executionDate.end))
          )
        ) {
          if (
            isAfter(
              new Date(convertTime(formData.executionTime.start)),
              new Date(convertTime(formData.executionTime.end))
            )
          ) {
            return toast.error(
              'O horario final precisa ser maior que o inicial para o mesmo dia'
            );
          }
        }

        if (
          !isAfter(
            new Date(
              convertDates(
                formData.executionDate.start,
                formData.executionTime.start
              )
            ),
            new Date()
          )
        ) {
          return toast.error(
            'O horario inicial precisa ser maior que o horario atual'
          );
        }

        if (
          isAfter(
            new Date(converDate(formData.executionDate.start)),
            new Date(converDate(formData.executionDate.end))
          )
        ) {
          return toast.error(
            'A data final precisa ser maior que o inicial para o mesmo dia'
          );
        }
      }

      if (validation) {
        formRef.current.setErrors({});
        const { success, data, errors } = await yupValidate(
          validation,
          formData
        );

        if (!success) {
          throw errors;
        }
      }

      if (formData.executionDate) {
        formData.executionDate = {
          start: converDate(formData.executionDate.start),
          end: converDate(formData.executionDate.end),
        };
      }

      if (formData.checklist) {
        const formatedChecklist = [];
        formData.checklist.forEach((element) => {
          formatedChecklist.push({ name: element, completed: false });
        });
        formData.checklist = formatedChecklist;
      }

      if (formData.executionTime) {
        formData.executionTime = {
          start: convertTime(formData.executionTime.start),
          end: convertTime(formData.executionTime.end),
        };
      }
      if (create) {
        await api.post(`/${url}`, formData);
      } else {
        await api.put(`/${url}/${initialData._id}`, formData);
      }

      closeModal();

      onSave();
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data);

        return toast.error(err.response.data);
      }

      if (err.response) {
        return toast.error(
          'Occoreu um erro, por favor tente novamente mais tarde'
        );
      }
      return formRef.current.setErrors(err);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (isOpen && formRef && formRef.current && initialData !== {}) {
      const payload = {
        ...initialData,
      };

      if (initialData.executionDate) {
        const executionDate = {
          start: format(
            new Date(initialData.executionDate.start),
            'yyyy-MM-dd'
          ),
          end: format(new Date(initialData.executionDate.end), 'yyyy-MM-dd'),
        };
        if (executionDate) {
          payload.executionDate = executionDate;
        }
      }
      if (initialData.executionTime) {
        const executionTime = {
          start: format(new Date(initialData.executionTime.start), 'HH:mm'),
          end: format(new Date(initialData.executionTime.end), 'HH:mm'),
        };
        if (executionTime) {
          payload.executionTime = executionTime;
        }
      }
      if (initialData.priceReward) {
        payload.priceReward = (initialData.priceReward / 100).toFixed(2);
      }

      formRef.current.setData(payload);
    }
  }, [formRef, initialData, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      initialData={initialData}
      maxWidth={600}
    >
      <HeaderModal>
        <Row>
          <ButtonClose onClick={() => closeModal()}>
            <img src={ArrowLeft} />
          </ButtonClose>
          <Left>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Left>
        </Row>
      </HeaderModal>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {children}
      </Form>

      <Separator />
      <Row style={{ marginTop: '50px', marginBottom: 20 }}>
        <Button
          color="outline-dark"
          round
          width={150}
          disabled={loading}
          label="Cancelar"
          onClick={() => closeModal()}
        />

        <Button
          color="action"
          round
          width={150}
          label={create ? 'Cadastrar' : 'Salvar'}
          loading={loading}
          onClick={() => formRef.current.submitForm()}
        />
      </Row>
    </Modal>
  );
};

export default forwardRef(ModalEditDrawer);

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Input from '~/components/Input';
import api from '~/services/api';

import { Label, Row } from './styles';

const EditForm = ({ data = {}, formRef }) => {
  const [loading, setloading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const handleChangePassword = async () => {
    setloading(true);
    try {
      await api.put('/user/change-password', {
        current_password: password,
        new_password: passwordRepeat,
      });

      toast.success('Senha alterada com sucesso');
    } catch (err) {
      if (err.response.data && err.response.data.error) {
        toast.error(err.response.data.error);
      } else {
        Object.entries(err.response.data).forEach(([key, value]) => {
          toast.error(value);
        });
      }
    } finally {
      setloading(false);
    }
  };

  return (
    <div
      style={{
        padding: 25,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Label>Informações:</Label>
        <Input name="name" placeholder="Nome" variant="border" fullWidth />
        <Input
          name="phone"
          placeholder="Telefone"
          variant="border"
          disabled
          returnUnmasked
          mask="cell"
          fullWidth
        />
        <Input
          name="email"
          disabled
          placeholder="Email"
          variant="border"
          fullWidth
        />

        <Label>Trocar senha</Label>

        <Row>
          <Input
            name="password"
            placeholder="Senha Atual"
            variant="border"
            fullWidth
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            name="passwordRepeat"
            placeholder="Nova Senha"
            variant="border"
            type="password"
            onChange={(e) => setPasswordRepeat(e.target.value)}
            fullWidth
          />
        </Row>
        <Button
          color="action"
          onClick={() => handleChangePassword()}
          label="Editar"
          width="150"
          loading={loading}
          round={20}
        />
      </div>
    </div>
  );
};

export default EditForm;

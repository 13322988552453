import { addMinutes } from 'date-fns';

const converDate = (date) => {
  const dateNow = new Date(`${date}T05:00:00Z`);

  return addMinutes(dateNow, dateNow.getTimezoneOffset()).toISOString();
};
const convertTime = (time) => {
  const dateNow = new Date(`2020-12-25T${time}:00Z`);

  return addMinutes(dateNow, dateNow.getTimezoneOffset()).toISOString();
};

const convertDates = (date, time) => {
  const dateNow = new Date(`${date}T${time}:00Z`);

  return addMinutes(dateNow, dateNow.getTimezoneOffset()).toISOString();
};

export { convertTime, converDate, convertDates };

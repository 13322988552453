import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '~/components/Button';
import { LoaderComponent } from '~/components/Button/styles';
import Multiple from '~/components/Charts/Multiple';
import { PaperContent, TitleInner } from '../MissoesChart/styles';
import { Row, Labels, LabelName, BoxLabel } from './styles';

function ClientesChart({ loading, data, totalClients }) {
  const [active, setactive] = useState();
  const [desactive, setdesactive] = useState();
  const [total, setTotal] = useState();

  const history = useHistory();

  useEffect(() => {
    if (data && data.clients) {
      setTotal(data.totalClients);

      let activeValue = 0;
      let desactiveValue = 0;
      data.clients.forEach((element) => {
        if (element._id) {
          activeValue = Math.round((element.total / data.totalClients) * 100);
        } else {
          if (element.total) {
            desactiveValue = Math.round(
              (element.total / data.totalClients) * 100
            );
          } else {
            desactiveValue = 0;
          }
        }
      });

      setactive(activeValue);
      setdesactive(desactiveValue);
    }
  }, [data]);

  console.log();

  return (
    <PaperContent>
      <TitleInner>Clientes</TitleInner>

      {!loading && total && desactive !== null && active !== null ? (
        <Multiple
          data={[active, desactive]}
          total={total}
          labels={['Ativos', 'Inativos']}
          colors={['#16ab6b', '#ef4747']}
          width={350}
        />
      ) : (
        <LoaderComponent
          type="ThreeDots"
          color="#ffc107"
          height={20}
          width={50}
          //3 secs
        />
      )}

      <Row>
        <Button
          onClick={() => history.push('/users')}
          label="Ver clientes"
          color="inline"
        />
        <Labels>
          <Row>
            <Row>
              <BoxLabel radius={4} color="rgba(0, 227, 150, 0.85)" />
              <LabelName>Ativos</LabelName>
            </Row>
            <Row>
              <BoxLabel radius={4} color="rgba(0, 143, 251, 0.85)" />

              <LabelName>Inativos</LabelName>
            </Row>
          </Row>
        </Labels>
      </Row>
    </PaperContent>
  );
}

export default ClientesChart;

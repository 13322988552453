/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import { Form as Unform } from '@unform/web';

import { Container } from './styles';

const Form = ({ children, onSubmit, ...rest }, ref) => {
  return (
    <Container className="root-form">
      <Unform ref={ref} onSubmit={onSubmit} {...rest}>
        {children}
      </Unform>
    </Container>
  );
};
export default forwardRef(Form);

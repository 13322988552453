import React, { useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import { useSelector } from 'react-redux';

import PlusCircle from '~/assets/svg/plus-circle.svg';
import TrashDark from '~/assets/svg/trash-dark.svg';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { Field } from '~/components/Input/styles';
import InputArea from '~/components/InputArea';
import ModalCreditCard from '~/components/Modals/ModalCreditCard';
import CreditCard from '~/components/Modals/ModalCreditCard/CreditCard';
import RadioButtonsGroup from '~/components/RadioButtonsGroup';
import api from '~/services/api';

import {
  Label,
  Row,
} from '../../../Dashboard/Users/CompanyTable/EditForm/styles';
import {
  Col,
  ButtonAdd,
  CheckListContent,
  CheckList,
  ContainerCard,
} from './styles';

const CreateMission = ({ data = {}, extraData, setExtraData, refModal }) => {
  const [turn, setTurnValue] = useState();

  const company = useSelector((state) => state.auth.company);

  const [checklist, setCheckList] = useState([]);
  const [item, setItem] = useState('');

  const [creditCard, setCreditCard] = useState();

  const modalCreditCard = useRef(null);

  const handleOpenModalCreditCard = (data = {}) => {
    modalCreditCard.current.openModal(data);
  };

  const refCheckList = useRef();

  useEffect(() => {
    setExtraData({
      turn,
      checklist,
      card_id: creditCard && creditCard.id,
      company: company && company._id,
    });
  }, [turn, checklist, creditCard]);

  useEffect(() => {
    if (extraData) {
      setTurnValue(extraData.turn);
      if (extraData.checklist) {
        const checkList = [];
        extraData.checklist.forEach((element) => {
          checkList.push(element.name);
        });
        setCheckList(checkList);
      }
    }
  }, []);

  const handleSetChecklist = (item) => {
    setCheckList([...checklist, item]);

    setItem('');
  };
  const handleDelete = (indexArray) => {
    const newArray = [];
    checklist.map((element, index) => {
      if (index !== indexArray) newArray.push(element);
    });

    setCheckList(newArray);
  };

  const onCepChange = async (cep) => {
    if (cep.length === 9) {
      try {
        const response = await api.get(`/user/address/${cep}`);

        refModal.current.setValues({
          address: {
            neighborhood: response.data.bairro,
            city: response.data.localidade,
            state: response.data.uf,
            street_name: response.data.logradouro,
            zipcode: response.data.cep,
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div
      style={{
        padding: 25,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Label>Dados da missão:</Label>
        <Row>
          <Col>
            <Input
              name="name"
              placeholder="Nome da missão"
              variant="border"
              fullWidth
            />
          </Col>

          <Input
            name="priceReward"
            placeholder="Valor recompensa"
            variant="border"
            mask="currency"
            defaultValue="100.00"
            disabled={extraData}
            returnUnmasked
            fullWidth
          />
        </Row>

        <InputArea
          name="description"
          placeholder="Descrição"
          variant="border"
          fullWidth
        />

        <Label>Checklist de atividade:</Label>

        <Row>
          <Col style={{ marginBottom: 15 }}>
            <Field
              placeholder="Nome"
              variant="border"
              value={item}
              ref={refCheckList}
              onChange={(e) => {
                setItem(e.target.value);
                refCheckList.current.value = '';
              }}
              fullWidth
            />
          </Col>
          <ButtonAdd onClick={() => item !== '' && handleSetChecklist(item)}>
            <img src={PlusCircle} />
          </ButtonAdd>
        </Row>

        {checklist.length > 0 && (
          <div
            style={{
              marginBottom: 15,
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            {checklist.map((element, index) => (
              <CheckListContent>
                <CheckList>• {element}</CheckList>
                <ButtonAdd onClick={() => handleDelete(index)}>
                  <img src={TrashDark} />
                </ButtonAdd>
              </CheckListContent>
            ))}
          </div>
        )}

        <Label>Data e horário da execução</Label>
        <Row>
          <Col>
            <Input
              name="executionDate.start"
              placeholder="Data início"
              variant="border"
              typeInput="date"
              fullWidth
            />
          </Col>

          <Input
            name="executionDate.end"
            placeholder="Data fim"
            variant="border"
            typeInput="date"
            fullWidth
          />
        </Row>
        <Row>
          <Col>
            <Input
              name="executionTime.start"
              placeholder="hora inicial"
              variant="border"
              typeInput="time"
              fullWidth
            />
          </Col>

          <Input
            name="executionTime.end"
            placeholder="Hora final"
            variant="border"
            typeInput="time"
            fullWidth
          />
        </Row>

        <Label>Turno</Label>
        <RadioButtonsGroup
          handleSelect={setTurnValue}
          defaultValue="daytime"
          options={[
            {
              label: 'Manhã',
              value: 'daytime',
            },
            {
              label: 'Tarde',
              value: 'afternoon',
            },
            {
              label: 'Noite',
              value: 'night',
            },
          ]}
        />

        <Label style={{ marginTop: 15 }}>Local de execução</Label>

        <Row>
          <Input
            name="address.zipcode"
            placeholder="CEP"
            variant="border"
            returnUnmasked
            mask="zipcode"
            onChange={(e) => onCepChange(e.target.value)}
          />
        </Row>
        <Row>
          <Col>
            <Input
              name="address.street_name"
              placeholder="Rua"
              variant="border"
              fullWidth
            />
          </Col>

          <Input
            name="address.street_number"
            placeholder="Número"
            variant="border"
            fullWidth
          />
        </Row>
        <Row>
          <Col>
            <Input
              name="address.complement"
              placeholder="Complemento"
              variant="border"
              fullWidth
            />
          </Col>

          <Input
            name="address.neighborhood"
            placeholder="Bairro"
            variant="border"
            fullWidth
          />
        </Row>
        <Row>
          <Col>
            <Input
              name="address.city"
              fullWidth
              placeholder="Cidade"
              variant="border"
            />
          </Col>

          <Input
            name="address.state"
            fullWidth
            placeholder="Estado"
            variant="border"
          />
        </Row>
        {!extraData && (
          <>
            {creditCard ? (
              <Row>
                <ContainerCard onClick={() => handleOpenModalCreditCard()}>
                  <CreditCard item={creditCard} />
                </ContainerCard>
              </Row>
            ) : (
              <Row>
                <Button
                  color="dark"
                  label="Selecionar método de pagamento"
                  onClick={() => handleOpenModalCreditCard()}
                  round={10}
                />
              </Row>
            )}
          </>
        )}

        <ModalCreditCard ref={modalCreditCard} onSave={setCreditCard} />
      </div>
    </div>
  );
};

export default CreateMission;

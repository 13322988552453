import styled from 'styled-components';

export const Col = styled.div`
  margin-right: 10px;
  display: flex;
  width: 100%;
`;

export const ButtonAdd = styled.div`
  margin-bottom: auto;
  cursor: pointer;
`;

export const CheckListContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
`;

export const CheckList = styled.div`
  word-break: break-all;
`;
export const ContainerCard = styled.div`
  width: 275px;
`;

import yup from '~/libs/yup';

const schema = yup.object().shape({
  card_holder_name: yup.string().required().label('Nome do Titular'),
  card_number: yup.string().length(16).required().label('Número do cartão'),
  card_expiration_date: yup
    .string()
    .length(4)
    .required()
    .label('Data de expiração'),
  card_cvv: yup.string().length(3).required().label('CVC'),
});

export default schema;

import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import { MessageModal, Row } from './styles';
import Button from '~/components/Button';

const ModalDelete = ({ onSave, message, ...rest }, ref) => {
  const [initialData, setInitialData] = useState({});

  const { isOpen, closeModal, openModal } = useModal();

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const handleModalSave = () => {
    onSave(initialData);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      initialData={initialData}
      maxWidth={400}
    >
      <MessageModal>{message}</MessageModal>
      <Row>
        <Button color="inline" label="Cancelar" onClick={() => closeModal()} />

        <Button
          color="inline"
          label="Confirmar"
          onClick={() => handleModalSave()}
        />
      </Row>
    </Modal>
  );
};

export default forwardRef(ModalDelete);

import styled, { css } from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  grid-gap: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  width: 100%;
  img {
    margin-right: 5px;
    width: 16px;
    height: 18px;
  }
`;

export const IconImage = styled.img`
  width: 25px !important;
  height: 28px !important;
  fill: #ffffff;
`;

export const DeleteIcon = styled.div`
  background-color: #ff9e00;
  width: 50px;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const RowAction = styled.div`
  display: flex;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const BlankIcon = styled.div`
  width: 50px;
`;

export const EditIcon = styled.div`
  background-color: #ffbc00;
  width: 50px;
  height: 100%;

  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const EditVertical = styled.div`
  background-color: #37465b;
  width: 50px;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ margin }) => margin && 'margin-left: auto;'};
`;

export const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 8px;
  background-color: #ebecee;
`;

export const RowHeader = styled.div`
  display: none;

  @media ${devices.tablet} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
  }
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
  ${({ noMargin }) => !noMargin && 'padding: 10px;'}
  @media ${devices.tablet} {
    width: ${({ size }) => size}%;
  }
  .show-profile {
    cursor: pointer;
  }
`;

export const HeaderText = styled.span`
  font-size: 13px;
  color: #9ca3aa;
`;

export const NameLabel = styled.h1`
  font-size: 19px;
  font-weight: 800;
  color: #37465b;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  line-height: 1.8em;
  ${({ half }) => !half && 'width: 100%'};
`;

export const SubLabelDate = styled.h1`
  font-size: 12px;
  font-weight: 300;
  color: #757f88;
`;

export const SubLabel = styled.h1`
  font-size: 12px;
  font-weight: 300;
  color: #757f88;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 3.5em;
  line-height: 1.8em;
`;

export const ExpiredLabel = styled.h1`
  font-size: 12px;
  font-weight: 300;
  color: rgba(65, 154, 249, 0.99);
`;

export const BodyEmpty = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  margin: auto;
`;
export const TextEmpty = styled.span`
  color: #9ca3aa;
  margin-top: 50px;
  margin-bottom: 50px;
`;

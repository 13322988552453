import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;

  display: flex;
  flex-wrap: wrap;
`;

export const Head = styled.div`
  grid-column: 1fr;

  @media ${devices.laptop} {
    grid-column: span 5;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;

export const ContentCol = styled.div`
  width: 100%;
  margin-bottom: 15px;
  @media ${devices.laptop} {
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}px;`}
    ${({ size }) => size && `width: ${size}%;`}
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #37465b;
  width: 70%;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #666666;
  width: 70%;
`;

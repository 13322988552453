/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';

import Header from '~/assets/img/no-image-featured-image.png';
import ProfileDefault from '~/assets/img/profile.png';
import Edit from '~/assets/svg/edit-2.svg';
import Button from '~/components/Button';
import ImageUpload from '~/components/ImageUpload';
import ModalEditDrawer from '~/components/Modals/ModalEditDrawer';
import Separator from '~/components/Separator';
import api from '~/services/api';
import { getMeRequest } from '~/store/modules/auth/actions';

import { LoaderComponent } from '../../../components/Button/styles';
import {
  Row,
  TextGray,
  ContentStatistics,
  Label,
} from '../../Dashboard/Users/CompanyTable/EditForm/styles';
import EditForm from './EditForm';
import {
  Container,
  Title,
  Subtitle,
  Content,
  EditContent,
  ContentBody,
  FooterContainer,
  ProfilePhoto,
  ContentInfo,
  LabelTitle,
  LabelValue,
  HeaderProfile,
} from './styles';

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);

  const modalRefEdit = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingCover, setLoadingCover] = useState(false);

  const [totalMission, setTotalMission] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [review, setReview] = useState({});

  const dispatch = useDispatch();

  const handleOpenModalEdit = () => {
    modalRefEdit.current.openModal(company);
  };

  const reflesh = () => {
    dispatch(getMeRequest());
  };

  const handleChangeCover = async (e) => {
    setLoadingCover(true);
    try {
      const pic = e.target.files[0];

      const body = new FormData();
      body.append('file', pic);
      body.append('type', 'cover');
      body.append('id', company._id);

      await api.put('/company/upload-assets', body);

      setTimeout(() => {
        reflesh();
      }, 1000);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingCover(false);
    }
  };

  const handleChangeProfile = async (e) => {
    setLoading(true);
    try {
      const pic = e.target.files[0];

      const body = new FormData();
      body.append('file', pic);
      body.append('type', 'logo');
      body.append('id', company._id);

      await api.put('/company/upload-assets', body);

      setTimeout(() => {
        reflesh();
      }, 1000);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getMe = async () => {
    try {
      const { data } = await api.get('/me');

      if (data.review.length) {
        setReview(data.review[0]);
      }
      setTotalMission(data.totalMission);
      setTotalActive(data.totalActive);
    } catch (err) {}
  };

  const handleStarsCount = () => {
    if (review.count > 0) {
      return review.total / review.count;
    }
    return 0.0;
  };

  const handlePercent = () => {
    if (review.count > 0) {
      const percent = (review.count * 5 - review.total) / review.total;
      return (100 - percent * 100).toFixed(0);
    }
    return 0;
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <Container>
      <Title>Meu Perfil</Title>
      <Subtitle>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
        mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
        Vestibulum interdum, augue et euismod tincidunt, elit urna tempus
        mauris.
      </Subtitle>
      <Content>
        <HeaderProfile>
          {!loadingCover ? (
            <>
              <img
                src={
                  company.assets && company.assets.cover
                    ? `${company.assets.cover}?${new Date().getTime()}`
                    : Header
                }
              />

              <ImageUpload onChange={handleChangeCover}>
                <EditContent>
                  <img src={Edit} />
                </EditContent>
              </ImageUpload>
            </>
          ) : (
            <LoaderComponent
              type="ThreeDots"
              color="#ffc107"
              height={20}
              width={50}
              // 3 secs
            />
          )}
        </HeaderProfile>

        <ContentBody>
          <div className="profile">
            <ProfilePhoto>
              {!loading ? (
                <>
                  <img
                    src={
                      company.assets && company.assets.logo
                        ? `${company.assets.logo}?${new Date().getTime()}`
                        : ProfileDefault
                    }
                  />
                  <ImageUpload onChange={handleChangeProfile}>
                    <EditContent>
                      <img src={Edit} />
                    </EditContent>
                  </ImageUpload>
                </>
              ) : (
                <LoaderComponent
                  type="ThreeDots"
                  color="#ffc107"
                  height={20}
                  width={50}
                  // 3 secs
                />
              )}
            </ProfilePhoto>
            <Row
              style={{
                marginBottom: 25,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Content>
                <TextGray>Pontuação: {handlePercent()}/100</TextGray>
                <StarRatingComponent
                  editing={false}
                  name="rate1"
                  starCount={5}
                  value={handleStarsCount()}
                  renderStarIcon={(index, value) => {
                    return (
                      <span style={index <= value ? { color: '#ffc107' } : {}}>
                        <i
                          className={
                            index <= value ? 'fas fa-star' : 'far fa-star'
                          }
                        />
                      </span>
                    );
                  }}
                  renderStarIconHalf={() => {
                    return (
                      <span style={{ color: '#ffc107' }}>
                        <span style={{ position: 'absolute' }}>
                          <i className="far fa-star" />
                        </span>
                        <span>
                          <i className="fas fa-star-half" />
                        </span>
                      </span>
                    );
                  }}
                />
              </Content>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: 25,
                  justifyContent: 'center',
                  marginBottom: 25,

                  borderRadius: 8,
                  backgroundColor: '#ebecee',
                }}
              >
                <ContentStatistics>
                  <Label style={{ textAlign: 'center' }}>Total</Label>
                  <TextGray>{totalMission}</TextGray>
                </ContentStatistics>
                <ContentStatistics>
                  <Label style={{ textAlign: 'center' }}>Ativas</Label>
                  <TextGray>{totalMission - totalActive}</TextGray>
                </ContentStatistics>
                <ContentStatistics>
                  <Label style={{ textAlign: 'center' }}>Finalizadas</Label>
                  <TextGray>{totalActive}</TextGray>
                </ContentStatistics>
              </div>
              <Button
                color="inline"
                label="Excluir conta"
                width="200"
                round={20}
              />
            </Row>
          </div>

          <div className="profileinfo">
            <ContentInfo>
              <LabelTitle>Informações:</LabelTitle>
              <LabelValue>{company.name}</LabelValue>
              <LabelValue>{company.social_name}</LabelValue>
              <LabelValue>{company.cnpj}</LabelValue>
            </ContentInfo>
            {company.address && (
              <ContentInfo>
                <LabelTitle>Informações:</LabelTitle>
                <LabelValue>CEP: {company.address.zipcode}</LabelValue>
                <LabelValue>
                  RUA: {company.address.street_name},{' '}
                  {company.address.street_number},{' '}
                  {company.address.neighborhood} - {company.address.city} /{' '}
                  {company.address.state}{' '}
                </LabelValue>
                <LabelValue>{company.cnpj}</LabelValue>
              </ContentInfo>
            )}

            <ContentInfo>
              <LabelTitle>Contato:</LabelTitle>
              <LabelValue>{company.email_contact}</LabelValue>
              <LabelValue>{company.phone_contact}</LabelValue>
            </ContentInfo>
          </div>
        </ContentBody>
        <Separator />
        <FooterContainer>
          <Button
            color="action"
            onClick={handleOpenModalEdit}
            label="Editar informações"
            width="250"
            round={20}
          />
        </FooterContainer>
      </Content>
      <ModalEditDrawer
        title="Visualizar perfil"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas."
        ref={modalRefEdit}
        onSave={reflesh}
        url="company"
      >
        <EditForm data={user} />
      </ModalEditDrawer>
    </Container>
  );
};

export default Profile;

import yup from '~/libs/yup';

const schema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  social_name: yup.string().required().label('Razão Social'),
  cnpj: yup.string().required().label('CPNJ'),
  phone_contact: yup.string().required().label('Telefone'),
  email_contact: yup.string().email().required().label('Email'),
  address: yup
    .object({
      zipcode: yup.string().required().label('CEP'),
      street_name: yup.string().required().label('Rua'),
      street_number: yup.string().required().label('Número'),
      neighborhood: yup.string().required().label('Senha'),
      city: yup.string().required().label('Senha'),
      state: yup.string().required().label('Senha'),
    })
    .required(),
  password: yup.string().min(6).required().label('Senha'),
  confirm_password: yup
    .string()
    .min(6)
    .equalTo(yup.ref('password'), 'A confirmação inválida')
    .required()
    .label('Confirmação'),
});

export default schema;

import React, { useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import ProfilePhoto from '~/assets/img/profile.png';
import Calendar from '~/assets/svg/calendar.svg';
import Check from '~/assets/svg/checkmark-circle-2.svg';
import Clock from '~/assets/svg/clock.svg';
import Close from '~/assets/svg/close.svg';
import Dolar from '~/assets/svg/dolar.svg';
import MenuVertical from '~/assets/svg/more-verticalwhite.svg';
import { LoaderComponent } from '~/components/Button/styles';
import { Avatar } from '~/components/Menu/Profile/styles';
import ModalDisapprove from '~/components/Modals/ModalDisapprove';
import { TaskerName } from '~/pages/Dashboard/Home/LastPendingMission/styles';
import {
  Container,
  Row,
  Col,
  ContentRow,
  NameLabel,
  BodyEmpty,
  SubLabel,
  SubLabelDate,
  TextEmpty,
  RowAction,
  EditVertical,
  IconImage,
  BlankIcon,
  DeleteIcon,
  EditIcon,
} from '~/pages/Dashboard/Users/UserTable/styles';
import {
  ContentStatus,
  MissionDetails,
  SubLabelStatus,
} from '~/pages/DashCompany/Missions/MissionTable/styles';
import api from '~/services/api';
import masks from '~/utils/masks';

import TableHeader from '../TableHeader';

const MissionTable = ({ data, loading, reflesh }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditIndex, setOpenEditIndex] = useState();
  const [approve, setApprove] = useState(false);

  const [conflit, setConflit] = useState();
  const [selected, setSelected] = useState();

  const history = useHistory();
  const modalRefApprove = useRef();

  const handleOpenModalApprove = (data = {}, aprove, conflitPayload) => {
    modalRefApprove.current.openModal(data);
    setApprove(aprove);
    setSelected(data);
    setConflit(conflitPayload);
  };

  useEffect(() => {}, []);

  const handleSubmitAprove = async (data) => {
    try {
      const review = {
        status: approve ? 'approved' : 'disapproved',
        date: new Date(),
      };

      data.review = review;

      await api.put(
        !conflit
          ? `mission/${data._id}/finish`
          : `mission/${data._id}/resolve-conflit`,
        data
      );

      reflesh();
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  return (
    <Container>
      <TableHeader />

      {!loading && data.data.length === 0 && (
        <BodyEmpty>
          <TextEmpty>Nenhum dado encontrado!</TextEmpty>
        </BodyEmpty>
      )}

      {loading && (
        <LoaderComponent
          type="ThreeDots"
          color="#37465b"
          height={100}
          width={100}
          // 3 secs
        />
      )}

      {!loading && (
        <>
          {data.data.map((element, index) => (
            <ContentRow>
              <Col size={10}>
                <ContentStatus status={element.review.status}>
                  <SubLabelStatus status={element.review.status}>
                    {element.review.status === 'approved'
                      ? 'Aprovada'
                      : 'Reprovada'}
                  </SubLabelStatus>
                </ContentStatus>
              </Col>
              <Col size={20}>
                <Row>
                  <Avatar
                    src={
                      (element.tasker && element.tasker.profile_photo !== '') ||
                      ProfilePhoto
                    }
                  />
                  <TaskerName>{element.description}</TaskerName>
                </Row>
              </Col>
              <Col size={15}>
                <Row>
                  <MissionDetails
                    onClick={() => history.push(`mission/${element._id}`)}
                  >
                    <NameLabel>{element.name}</NameLabel>
                  </MissionDetails>
                </Row>
              </Col>

              <Col size={15}>
                <Row>
                  <img src={Calendar} />
                  <SubLabelDate>
                    {format(
                      new Date(element.executionDate.start),
                      'dd/MM/yyyy'
                    )}{' '}
                    -{' '}
                    {format(new Date(element.executionDate.end), 'dd/MM/yyyy')}
                  </SubLabelDate>
                </Row>
              </Col>
              <Col size={20}>
                <Row>
                  {element.executionTime ? (
                    <>
                      <img src={Clock} />
                      <SubLabelDate>
                        Entre{' '}
                        {format(new Date(element.executionTime.start), 'HH:mm')}{' '}
                        e {format(new Date(element.executionTime.end), 'HH:mm')}{' '}
                      </SubLabelDate>
                    </>
                  ) : (
                    '-'
                  )}
                </Row>
              </Col>
              <Col size={10}>
                <Row>
                  <img src={Dolar} />
                  <SubLabel>R$ {masks.currency(element.priceReward)}</SubLabel>
                </Row>
              </Col>
              {(!element.conflit || element.conflit !== 'finalized') && (
                <Col noMargin size={10}>
                  {openEdit && openEditIndex === index ? (
                    <RowAction margin>
                      <EditIcon
                        onClick={() => {
                          handleOpenModalApprove(element, false, true);
                        }}
                      >
                        <IconImage src={Close} />
                      </EditIcon>
                      <DeleteIcon
                        onClick={() => {
                          handleOpenModalApprove(element, true, true);
                        }}
                      >
                        <IconImage src={Check} />
                      </DeleteIcon>
                    </RowAction>
                  ) : (
                    <RowAction>
                      <BlankIcon />
                      <EditVertical
                        onClick={() => {
                          setOpenEdit(true);
                          setOpenEditIndex(index);
                        }}
                      >
                        <IconImage fill="white" src={MenuVertical} />
                      </EditVertical>
                    </RowAction>
                  )}
                </Col>
              )}
            </ContentRow>
          ))}
        </>
      )}
      <ModalDisapprove
        ref={modalRefApprove}
        approve={approve}
        message={
          approve
            ? `Tem certeza que deseja aprovar
            essa missão? Você irá liberar o valor de R$ ${masks.currency(
              selected.priceReward - selected.tax_fee
            )}`
            : 'Tem certeza que deseja reprovar essa missão ? '
        }
        onSave={handleSubmitAprove}
      />
    </Container>
  );
};

export default MissionTable;

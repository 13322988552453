import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  @media ${devices.laptop} {
    ${({ size }) => `width: ${size}%;`}
  }
  width: 100%;
  height: 100%;
  padding: 0px 5px;
`;

export const Row = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const InnerContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 15px 0px;
  text-align: center;
`;

export const ProfilePhoto = styled.div`
  height: 95px;
  width: 95px;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ffbc00;
  border-radius: 100px;
  margin 0px auto;
  img {
    width: 90px;
    height: 90px;
    border-radius: 100px;
  }
`;

export const LoadingContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 200px;
`;

export const ContentCarousel = styled.div`
  border-radius: 8px;
  background-color: #ffbc00;
  padding: 24px;
`;
export const Content = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
`;

export const Missions = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

export const Head = styled.div`
  grid-column: 1fr;

  @media ${devices.laptop} {
    grid-column: span 5;
  }
`;

export const CompanyName = styled.div`
  font-size: 18px;
  font-weight: 800;

  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  color: #37465b;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #37465b;
  width: 100%;
  margin-bottom: 5px;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #ffffff;
  width: 70%;
`;

import React, { useEffect, useState } from 'react';

import Pagination from '~/components/Pagination';
import { Col, Row } from '~/pages/Dashboard/Users/UserTable/styles';
import api from '~/services/api';

import {
  Container,
  Content,
  Title,
  FooterPagination,
} from '../../Dashboard/Users/styles';
import Statistics from './Statistics';
import { Subtitle } from './styles';
import TableList from './TableList';

const INITIAL_DATA = {
  data: [],
  page: 1,
  perPage: 8,
  totalPages: 1,
};
export const Financial = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const [monthSelected, setmonthSelected] = useState();

  const getData = async (page = 1) => {
    try {
      setLoading(true);

      const response = await api.get('/finance/', {
        params: {
          perPage: 6,
          page,
          month: monthSelected.value,
        },
      });

      console.log(response.data);
      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [monthSelected]);

  const handleChangePage = (page) => getData(page);

  return (
    <Container>
      <Row>
        <Col size={40}>
          <Title>Financeiro</Title>
          <Subtitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et
            mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
            Vestibulum interdum, augue et euismod tincidunt, elit urna tempus
            mauris.
          </Subtitle>
        </Col>
        <Col size={60}>
          <Statistics
            setmonthSelected={setmonthSelected}
            monthSelected={monthSelected}
          />
        </Col>
      </Row>

      <Content>
        <TableList data={data} loading={loading} reflesh={getData} />
      </Content>
      <FooterPagination>
        <Pagination
          onChange={handleChangePage}
          page={data.page}
          pageSize={data.limit}
          total={data.totalData}
          totalPages={data.totalPages}
          position="flex-end"
        />
      </FooterPagination>
    </Container>
  );
};

export default Financial;

import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Label = styled.h1`
  font-size: 13px;
  font-weight: 800;
  color: #37465b;
  margin-bottom: 15px;
  width: 100%;
`;

export const Row = styled.div`
  @media ${devices.laptop} {
    flex-direction: row;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const TextGray = styled.h1`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #999fa9;
`;

export const ContentStatistics = styled.div`
  background-color: #ebecee;
  padding: 15px;
  width: 85px;
`;
export const Content = styled.div`
  padding: 0px 25px;
`;

export const ContainerProfile = styled.div`
  border-radius: 100px;
  border: 2px solid #ff9e00;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ProfilePhoto = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 100px;
  margin: 0 auto;
`;

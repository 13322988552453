import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #666666;
  width: 100%;
`;

import React from 'react';

import CreditCardIMaster from '~/assets/svg/cc-mastercard-brands.svg';
import CreditCardVisa from '~/assets/svg/cc-visa-brands.svg';
import CreditCardIcon from '~/assets/svg/credit-card-solid.svg';

import {
  Container,
  CardHolder,
  Number,
  Content,
  ContentIcon,
  InnerContent,
} from './styles';

function CreditCard({ item, handleModalSave }) {
  return (
    <Container onClick={() => handleModalSave && handleModalSave(item)}>
      <ContentIcon>
        {item.brand === 'visa' && <img fill="#37465b" src={CreditCardVisa} />}
        {item.brand === 'mastercard' && (
          <img fill="#37465b" src={CreditCardIMaster} />
        )}
        {item.brand !== 'visa' && item.brand !== 'mastercard' && (
          <img fill="#37465b" src={CreditCardIcon} />
        )}
        <InnerContent>
          <Number>**** **** **** {item.last_digits}</Number>
          <Content>
            <CardHolder>{item.holder_name}</CardHolder>
            <CardHolder>
              {`${item.expiration_date.slice(
                0,
                2
              )}/${item.expiration_date.slice(2)}`}
            </CardHolder>
          </Content>
        </InnerContent>
      </ContentIcon>
    </Container>
  );
}

export default CreditCard;

import React, { useEffect, useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MailIcon from '@material-ui/icons/Mail';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import LogoImage from '~/assets/img/tesk-transparent.png';
import Award from '~/assets/svg/award.svg';
import Clipboard from '~/assets/svg/clipboard.svg';
import CreditCard from '~/assets/svg/credit-card.svg';
import HomeIcon from '~/assets/svg/home.svg';
import Info from '~/assets/svg/info.svg';
import LockIcon from '~/assets/svg/lock.svg';
import Logout from '~/assets/svg/log-out.svg';
import MenuPreference from '~/assets/svg/menu-2.svg';
import Message from '~/assets/svg/message-circle.svg';
import UserIcon from '~/assets/svg/person.svg';
import { signOutRequest } from '~/store/modules/auth/actions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {},
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#37465b',
    borderRight: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: 118,
    margin: '25px 20px',
  },
  itemList: {
    color: '#fff',
    fontSize: 15,
    fontWeight: 600,
  },
  itemListSelected: {
    color: '#37465b',
    fontSize: 15,
    fontWeight: 600,
  },
  selectedMenu: {
    backgroundColor: '#f2f3f4',
    '&:hover': {
      background: '#fff',
    },
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
  },
  list: {
    marginLeft: 10,
  },
}));

export default function MiniDrawer({ open, setOpen, ...props }) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const { pathname } = useLocation();

  const [menuArray, setMenuArray] = useState([]);
  const history = useHistory();

  const { company } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleClickLogout = () => {
    dispatch(signOutRequest());
    history.push('/login');
  };

  const menuItems = [
    {
      name: 'Dashboard',
      key: '/',
      icon: HomeIcon,
      disabled: false,

      action: () => history.push('/'),
    },
    {
      name: 'Usuários',
      key: '/users',
      disabled: false,
      icon: UserIcon,
      action: () => history.push('/users'),
    },
    {
      name: 'Missões',
      key: '/mission',
      disabled: false,
      icon: Clipboard,
      action: () => history.push('/mission'),
    },
    {
      name: 'Pendências',
      key: '/conflit',
      disabled: false,
      icon: Info,
      action: () => history.push('/conflit'),
    },
    {
      name: 'Financeiro',
      key: '/financial',
      icon: CreditCard,
      disabled: false,

      action: () => history.push('/financial'),
    },
    {
      name: 'Meu Perfil',
      key: '/profile',
      icon: LockIcon,
      disabled: false,

      action: () => history.push('/profile'),
    },
    { name: 'Sair', key: '', icon: Logout, action: () => handleClickLogout() },
  ];

  const menuItemsCompany = [
    {
      name: 'Dashboard',
      key: '/company',
      icon: HomeIcon,
      disabled: false,
      action: () => history.push('/company'),
    },
    {
      name: 'Meu Perfil',
      key: '/company/profile',
      icon: UserIcon,
      disabled: false,

      action: () => history.push('/company/profile'),
    },
    {
      name: 'Minhas missões',
      key: '/company/mission',
      icon: Clipboard,
      disabled: false,

      action: () => history.push('/company/mission'),
    },
    {
      name: 'Financeiro',
      key: '/company/financial',
      icon: CreditCard,
      disabled: false,

      action: () => history.push('/company/financial'),
    },
    {
      name: 'Preferências',
      key: '/company/preferences',
      icon: MenuPreference,
      disabled: true,

      action: () => history.push('/company/preferences'),
    },
    {
      name: 'Precisa de ajuda?',
      key: '/company/help',
      icon: Message,
      disabled: true,

      action: () => history.push('/company/help'),
    },
    {
      name: 'Termos de uso',
      key: '/company/terms',
      icon: Info,
      disabled: true,

      action: () => history.push('/company/terms'),
    },
    {
      name: 'Sobre',
      key: '/company/about',
      icon: Award,
      disabled: true,

      action: () => history.push('/company/about'),
    },
    { name: 'Sair', key: '', icon: Logout, action: () => handleClickLogout() },
  ];

  useEffect(() => {
    if (company) {
      setMenuArray(menuItemsCompany);
    } else {
      setMenuArray(menuItems);
    }
  }, [company]);
  const drawer = (
    <div>
      <img className={classes.logo} src={LogoImage} alt="React Logo" />
      <div className={classes.toolbar} />
      <List className={classes.list}>
        {menuArray.map((element, index) => (
          <ListItem
            className={pathname === element.key && classes.selectedMenu}
            button
            disabled={element.disabled}
            onClick={element.action}
            key={element.name}
          >
            <ListItemIcon>
              <img src={element.icon} alt="React Logo" />
            </ListItemIcon>
            <ListItemText
              className={
                pathname === element.key
                  ? classes.itemListSelected
                  : classes.itemList
              }
              primary={element.name}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const ContentStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin: auto;
  border: 1px solid
    ${({ status }) => (status === 'approved' ? '#16ab6b' : '#ef4747')};
  border-radius: 50px;

  ${({ statusString, status }) =>
    (!status &&
      statusString === 'f_1-finished' &&
      'border: 1px solid #ffbc00') ||
    (!status &&
      statusString === 'd_2-doing' &&
      'border: 1px solid rgba(65, 154, 249, 0.99)') ||
    (!status &&
      statusString === 'd_1-accepted' &&
      'border: 1px solid rgba(65, 154, 249, 0.99)')};
`;

export const MissionDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
`;

export const SubLabelStatus = styled.h1`
  font-size: 12px;
  font-weight: 300;
  color: #757f88;
  padding: 0px 5px;

  color: ${({ status }) => (status === 'approved' ? '#16ab6b' : '#ef4747')};
  ${({ statusString, status }) =>
    (!status && statusString === 'f_1-finished' && 'color:#ffbc00') ||
    (!status &&
      statusString === 'd_2-doing' &&
      'color:rgba(65, 154, 249, 0.99)') ||
    (!status &&
      statusString === 'd_1-accepted' &&
      'color:rgba(65, 154, 249, 0.99)')};
`;

import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  padding: 15px;
  margin-right: 15px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 15px;
`;

export const MarginBottom = styled.div`
  background-color: #37465b;
  height: 1px;
  width: 100%;
`;

export const Content = styled.div`
  display: inline-block;

  padding: 15px;
  height: 200px;
  margin: 15px 5px;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ color }) =>
    color === 'white' ? '#ffffff' : '#f2f3f4'};

  @media ${devices.laptop} {
    flex-direction: row;
    width: 312px;
    ${({ fullWidth }) => fullWidth && `width: 100%;`}
  }
`;

export const Text = styled.div`
  font-size: 13px;
  color: #273645;
`;

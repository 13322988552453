import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Cards from 'react-credit-cards';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import { LoaderComponent } from '~/components/Button/styles';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import { Col } from '~/pages/DashCompany/Missions/CreateMission/styles';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import credit_card from '~/validators/credit_card.schema';
import CreditCard from './CreditCard';

import { ContainerLoading, MessageModal, Row, FormView } from './styles';

const ModalCreditCard = ({ onSave, message, ...rest }, ref) => {
  const [initialData, setInitialData] = useState({});

  const [tabs, setTabs] = useState(1);
  const [loading, setloading] = useState(false);

  const [credits, setCredits] = useState([]);

  const [loadingCard, setloadingCard] = useState(false);

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [cvc, setCVC] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocus] = useState('');

  const { isOpen, closeModal, openModal } = useModal();

  const handleModalSave = (item) => {
    onSave(item);
    closeModal();
  };

  const formRef = useRef(null);

  const getCreditCards = async () => {
    setloadingCard(true);
    try {
      const { data } = await api.get(`/creditcard`);

      setCredits(data);
    } catch (err) {
      console.log(err);
    } finally {
      setloadingCard(false);
    }
  };

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
      getCreditCards();
    },
  }));

  const handleSubmit = async (formData) => {
    setloading(true);

    try {
      formRef.current.setErrors({});
      const { success, data, errors } = await yupValidate(
        credit_card,
        formData
      );

      if (!success) {
        throw errors;
      }

      await api.post(`/creditcard`, formData);

      toast.success('Cartão cadastrado com sucesso!');

      getCreditCards();
      setTabs(1);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.response) {
        toast.error(err.response.data.response.errors[0].message);
      } else {
        formRef.current.setErrors(err);
      }
    } finally {
      setloading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      initialData={initialData}
      maxWidth={400}
    >
      {tabs === 1 ? (
        <>
          {loadingCard && (
            <ContainerLoading>
              <LoaderComponent
                type="ThreeDots"
                color="#37465b"
                height={50}
                width={150}
                // 3 secs
              />
            </ContainerLoading>
          )}
          {!loadingCard && credits.length === 0 && (
            <ContainerLoading>
              <MessageModal>Nenhum cartão encontrado</MessageModal>
            </ContainerLoading>
          )}
          {!loadingCard && credits.length > 0 && (
            <ContainerLoading
              style={{
                overflowY: 'scroll',
              }}
            >
              {credits.map((element) => (
                <CreditCard
                  handleModalSave={handleModalSave}
                  item={element}
                  key={element.id}
                />
              ))}
            </ContainerLoading>
          )}

          <Row>
            <Button
              color="inline"
              label="Adicionar cartão"
              disabled={loadingCard}
              onClick={() => setTabs(2)}
            />
          </Row>
        </>
      ) : (
        <>
          <Cards
            cvc={cvc}
            expiry={expiry}
            focused={focus}
            name={name}
            number={number}
          />
          <FormView ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="card_number"
              placeholder="Numero do cartão"
              variant="border"
              mask="creditOrDebitCard"
              returnUnmasked
              onChange={(e) => setNumber(e.target.value)}
              onFocus={() => setFocus('name')}
              fullWidth
            />
            <Input
              name="card_holder_name"
              placeholder="Nome do titular"
              variant="border"
              onChange={(e) => setName(e.target.value)}
              onFocus={() => setFocus('name')}
              fullWidth
            />
            <Row>
              <Col>
                <Input
                  name="card_expiration_date"
                  placeholder="Data de expiração"
                  variant="border"
                  returnUnmasked
                  mask="creditOrDebitCardExpiry"
                  onChange={(e) => setExpiry(e.target.value)}
                  onFocus={() => setFocus('expiry')}
                  fullWidth
                />
              </Col>
              <Col>
                <Input
                  name="card_cvv"
                  mask="creditOrDebitCardCvv"
                  placeholder="CVC"
                  returnUnmasked
                  onChange={(e) => setCVC(e.target.value)}
                  onFocus={() => setFocus('cvc')}
                  variant="border"
                  fullWidth
                />
              </Col>
            </Row>

            <Row>
              <Button
                color="inline"
                label="Voltar"
                loadingDark
                disabled={loading}
                onClick={() => setTabs(1)}
              />
              <Button
                color="inlineDark"
                label="Salvar"
                loadingDark
                loading={loading}
                onClick={() => formRef.current.submitForm()}
              />
            </Row>
          </FormView>
        </>
      )}
    </Modal>
  );
};

export default forwardRef(ModalCreditCard);

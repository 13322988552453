import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import api from '~/services/api';

import ClockAndDate from '../Components/ClockAndDate';
import ClientesChart from './ClientesChart';
import LastPendingMission from './LastPendingMission';
import MissoesChart from './MissoesChart';
import {
  Container,
  Head,
  Title,
  Subtitle,
  ContentRow,
  ContentCol,
} from './styles';

const INITIAL_DATA = {
  data: [],
  page: 1,
  perPage: 8,
  totalPages: 1,
};

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);

  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const [statistics, setStatistics] = useState({});

  const [loadingStatitics, setLoadingStatitics] = useState(false);

  const getStatistics = async (page = 1) => {
    try {
      setLoadingStatitics(true);

      const query = {
        clients: true,
        mission: true,
      };

      const response = await api.get('/statistics', {
        params: query,
      });

      setStatistics(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingStatitics(false);
    }
  };

  const getData = async (page = 1) => {
    try {
      setLoading(true);

      const query = {
        perPage: 3,
        page,
        reviewed: true,
        status: 'f_1-finished',
        conflit: 'pending',
      };

      const response = await api.get('/mission', {
        params: query,
      });

      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getStatistics();
  }, []);

  return (
    <Container>
      <Title>Olá {user.name},</Title>
      <ClockAndDate />

      <Subtitle>
        Lorem ipsum dolor sit amest, consectetur adipiscing elit. Praesent et
        mattis neque. Aenean et mi finibus, varius nunc id, finibus felis.
        Vestibulum interdum, augue et euismod tincidunt.
      </Subtitle>
      <ContentRow>
        <ContentCol size={40} paddingRight={20}>
          <ClientesChart loading={loadingStatitics} data={statistics} />
        </ContentCol>

        <ContentCol size={60}>
          <MissoesChart loading={loadingStatitics} data={statistics} />
        </ContentCol>
      </ContentRow>
      <LastPendingMission
        finished
        reflesh={getData}
        data={data}
        loading={loading}
      />
    </Container>
  );
};

export default Dashboard;

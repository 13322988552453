import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Label = styled.h1`
  font-size: 13px;
  font-weight: 800;
  color: #37465b;
  margin-bottom: 15px;
  width: 100%;
`;

export const Row = styled.div`
  @media ${devices.laptop} {
    flex-direction: row;
  }
  display: flex;
  align-items: center;
  width: 100%;
`;

export const DocumentPhoto = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 20px;
  margin-right: 25px;
`;

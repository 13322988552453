export const signInRequest = (credentials) => {
  return {
    type: '@auth/SIGNIN_REQUEST',
    data: credentials,
  };
};

export const getMeRequest = () => {
  return {
    type: '@auth/GET_ME_REQUEST',
  };
};

export const getMeSuccess = (user) => {
  return {
    type: '@auth/GET_ME_SUCCESS',
    payload: user,
  };
};

export const signInSuccess = (user) => {
  return {
    type: '@auth/SIGNIN_SUCCESS',
    payload: user,
  };
};

export const companyRequest = (token) => {
  return {
    type: '@auth/COMPANY_REQUEST',
    payload: token,
  };
};

export const companySuccess = (company) => {
  return {
    type: '@auth/COMPANY_SUCCESS',
    payload: company,
  };
};

export const signInError = () => {
  return {
    type: '@auth/SIGNIN_ERROR',
  };
};

export const signOutRequest = () => {
  return {
    type: '@auth/SIGNOUT_REQUEST',
  };
};

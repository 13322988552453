import styled, { css, keyframes } from 'styled-components';

export const MessageModal = styled.span`
  font-size: 18px;
  text-align: center;
  color: #212121;
  margin-top: 50px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
`;

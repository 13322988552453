import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import Input from '~/components/Input';

import { MessageModal, Row } from './styles';
import InputArea from '~/components/InputArea';
import yupValidate from '~/utils/yupValidate';
import { disapproveSchema } from '~/validators/mission.schema';

const ModalDisapprove = ({ onSave, message, approve, ...rest }, ref) => {
  const [initialData, setInitialData] = useState({});

  const { isOpen, closeModal, openModal } = useModal();

  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const handleModalSave = () => {
    onSave(initialData);
    closeModal();
  };

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});
      const { success, data, errors } = await yupValidate(
        disapproveSchema,
        formData
      );

      if (!success) {
        throw errors;
      }

      formData.date = new Date();

      const payload = {};
      payload.report_conflit_by_company = formData;

      payload._id = initialData._id;

      onSave(payload);
      closeModal();
    } catch (err) {
      return formRef.current.setErrors(err);
    } finally {
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      initialData={initialData}
      maxWidth={400}
    >
      <MessageModal>{message}</MessageModal>
      {!approve && (
        <Form style={{ marginTop: 20 }} ref={formRef} onSubmit={handleSubmit}>
          <Input name="reason" placeholder="Razão" variant="border" fullWidth />
          <InputArea
            name="obs"
            placeholder="Observação"
            variant="border"
            fullWidth
          />
        </Form>
      )}

      <Row>
        <Button color="inline" label="Cancelar" onClick={() => closeModal()} />

        <Button
          color="inline"
          label="Confirmar"
          onClick={() =>
            approve ? handleModalSave() : formRef.current.submitForm()
          }
        />
      </Row>
    </Modal>
  );
};

export default forwardRef(ModalDisapprove);

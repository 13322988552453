import React from 'react';

import Chart from 'react-apexcharts';

const Multiple = ({ width, color, data, total, labels }) => {
  const optionsRadial = {
    chart: {
      height: 200,
      type: 'radialBar',
    },
    colors: color,
    plotOptions: {
      radialBar: {
        dataLabels: {
          total: {
            show: true,
            label: 'TOTAL',
            formatter(w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return total;
            },
          },
        },
      },
    },
    labels,
  };
  const seriesRadial = data;

  return (
    <Chart
      options={optionsRadial}
      series={seriesRadial}
      type="radialBar"
      width={width || '200'}
    />
  );
};

export default Multiple;

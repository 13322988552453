import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '~/components/Button';

import Input from '~/components/Input';
import api from '~/services/api';

import {
  Label,
  Row,
} from '../../../Dashboard/Users/CompanyTable/EditForm/styles';

const EditForm = (data = {}) => {
  const [loading, setloading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const handleChangePassword = async () => {
    setloading(true);
    try {
      await api.put('/user/change-password', {
        current_password: password,
        new_password: passwordRepeat,
      });

      toast.success('Senha alterada com sucesso');
    } catch (err) {
      console.log(err.response.data);
      if (err.response.data && err.response.data.error) {
        toast.error(err.response.data.error);
      } else {
        Object.entries(err.response.data).forEach(([key, value]) => {
          toast.error(value);
        });
      }
    } finally {
      setloading(false);
    }
  };

  return (
    <div
      style={{
        padding: 25,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Label>Informações:</Label>
        <Row>
          <Input
            name="name"
            placeholder="Nome empresa"
            variant="border"
            fullWidth
          />
          <Input
            name="social_name"
            placeholder="Nome social"
            variant="border"
            fullWidth
          />
        </Row>

        <Input
          name="cnpj"
          mask="cnpj"
          returnUnmasked
          placeholder="CPNJ"
          variant="border"
          fullWidth
        />

        <Label>Localização:</Label>

        <Row>
          <Input
            name="address.zipcode"
            placeholder="CEP"
            variant="border"
            mask="zipcode"
            fullWidth
            returnUnmasked
          />
        </Row>
        <Row>
          <Input
            name="address.street_name"
            placeholder="Rua"
            variant="border"
            fullWidth
          />
          <Input
            name="address.street_number"
            placeholder="Número"
            variant="border"
            fullWidth
          />
        </Row>
        <Row>
          <Input
            name="address.complement"
            placeholder="Complemento"
            variant="border"
            fullWidth
          />
          <Input
            name="address.neighborhood"
            placeholder="Bairro"
            variant="border"
            fullWidth
          />
        </Row>
        <Row>
          <Input
            name="address.city"
            fullWidth
            placeholder="Cidade"
            variant="border"
          />
          <Input
            name="address.state"
            fullWidth
            placeholder="Estado"
            variant="border"
          />
        </Row>
        <Label>Contato:</Label>

        <Row>
          <Input
            name="phone_contact"
            placeholder="Telefone de contato"
            variant="border"
            mask="cell"
            fullWidth
            returnUnmasked

          />
          <Input
            name="email_contact"
            placeholder="Email de contato"
            variant="border"
            fullWidth
          />
        </Row>
        <Label>Trocar senha</Label>

        <Row>
          <Input
            name="password"
            placeholder="Senha Atual"
            variant="border"
            fullWidth
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            name="passwordRepeat"
            placeholder="Nova Senha"
            variant="border"
            type="password"
            onChange={(e) => setPasswordRepeat(e.target.value)}
            fullWidth
          />
        </Row>
        <Button
          color="action"
          onClick={() => handleChangePassword()}
          label="Editar Senha"
          width="150"
          loading={loading}
          round={20}
        />
      </div>
    </div>
  );
};

export default EditForm;

import styled from 'styled-components';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
  background-color: transparent;
  width: 100%;
  margin: 15px 0px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Card = styled.div`
  width: 50%;
  background-color: #fff;
  padding: 15px;
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 rgba(239, 246, 253, 0.35);
`;

export const TitleCard = styled.div`
  font-size: 17px;
  font-weight: 800;
  width: 100%;

  color: #273645;
`;

export const SubtitleCard = styled.div`
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  color: #9ca3aa;
`;

import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import Financial from '~/pages/Dashboard/Financial';
import Home from '~/pages/Dashboard/Home';
import PendingMissions from '~/pages/Dashboard/Missions/PendingMissions';
import Profile from '~/pages/Dashboard/Profile';
import Users from '~/pages/Dashboard/Users';
import FinancialCompany from '~/pages/DashCompany/Financial';
import HomeCompany from '~/pages/DashCompany/Home';
import MissionDetails from '~/pages/DashCompany/MissionDetails';
import Missions from '~/pages/DashCompany/Missions';
import ProfileCompany from '~/pages/DashCompany/Profile';
import Login from '~/pages/Login';
import CreateAccount from '~/pages/CreateAccount';

import NotFound from '~/pages/NotFound';

import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import PrivateRouteCompany from './PrivateRouteCompany';
import PublicRoute from './PublicRoute';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <LoginRoute path="/login" component={Login} />
        <LoginRoute path="/create-account" component={CreateAccount} />

        <PrivateRoute path="/" component={Home} exact />
        <PrivateRoute path="/users" component={Users} exact />
        <PrivateRoute path="/profile" component={Profile} exact />
        <PrivateRoute path="/mission" component={Missions} exact />
        <PrivateRoute path="/financial" component={Financial} exact />
        <PrivateRoute path="/mission/:id" component={MissionDetails} exact />
        <PrivateRoute path="/conflit" component={PendingMissions} exact />

        <PrivateRouteCompany path="/company" component={HomeCompany} exact />
        <PrivateRouteCompany
          path="/company/profile"
          component={ProfileCompany}
          exact
        />

        <PrivateRouteCompany
          path="/company/mission"
          component={Missions}
          exact
        />

        <PrivateRouteCompany
          path="/company/financial"
          component={FinancialCompany}
          exact
        />

        <PrivateRouteCompany
          path="/company/mission/:id"
          component={MissionDetails}
          exact
        />
        <PublicRoute path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

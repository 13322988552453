import React from 'react';

import PropTypes from 'prop-types';

import { Container, LoaderComponent } from './styles';

const Button = ({
  type,
  label,
  fullWidth,
  loadingDark,
  disabled,
  color,
  className,
  loading,
  round,
  ...rest
}) => {
  return (
    <Container
      {...rest}
      className={`root-button ${className} ${color}`}
      fullWidth={fullWidth}
      type={type}
      disabled={loading || disabled}
      round={round}
    >
      {loading ? (
        <LoaderComponent
          type="ThreeDots"
          color={loadingDark ? ' #37465b' : '#fff'}
          height={20}
          width={50}
          //3 secs
        />
      ) : (
        <> {label} </>
      )}
    </Container>
  );
};

Button.defaultProps = {
  type: 'button',
  className: '',
  color: 'action',
  fullWidth: false,
  loading: false,
  width: null,
  loadingDark: false,
  disabled: false,
  round: false,
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  round: PropTypes.bool,
};

export default Button;

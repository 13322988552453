import React, { useRef, useEffect, useCallback } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import masks from '~/utils/masks';

import { Container, Label, Field, Error, FieldMaterial } from './styles';

const Input = ({
  type,
  label,
  name,
  mask,
  typeInput,
  colorLabel,
  returnUnmasked,
  className,
  fullWidth,
  variant,
  ...rest
}) => {
  const inputRef = useRef(null);

  console.log(typeInput);
  // defaultValue
  const { fieldName, registerField, error, defaultValue = '' } = useField(name);

  useEffect(() => {
    if (inputRef.current && !rest.value)
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        getValue(ref) {
          if (mask && returnUnmasked) {
            return masks.unmask(ref.value);
          }

          return ref.value;
        },
        setValue: (ref, value) => {
          if (!value) return;

          const val = value;
          if (mask) {
            if (!masks[mask]) throw console.error('Máscara não definida');
            ref.value = masks[mask](val);
          } else {
            ref.value = val;
          }
        },
      });
  }, [fieldName, registerField, rest.value]);

  // To use masks
  const handleChange = useCallback(
    (e) => {
      e.persist();

      if (mask) {
        if (!masks[mask]) throw new Error('Máscara não definida');

        const { value } = e.target;
        inputRef.current.value = masks[mask](value);
      }

      if (rest.onChange) rest.onChange(e);
    },
    [inputRef, mask, rest]
  );

  const inputProps = {
    ...(rest.value ? { value: rest.value } : { defaultValue }),
    ...rest,
    onChange: handleChange,
  };

  return (
    <Container className={`root-input ${className}`} fullWidth={fullWidth}>
      {label && (
        <Label htmlFor={fieldName} color={colorLabel}>
          {label}
        </Label>
      )}
      {typeInput === 'time' || typeInput === 'date' ? (
        <FieldMaterial
          inputRef={inputRef}
          fullWidth={fullWidth}
          type={typeInput}
          id={fieldName}
          name={fieldName}
        />
      ) : (
        <Field
          fullWidth={fullWidth}
          ref={inputRef}
          type={type}
          id={fieldName}
          name={fieldName}
          error={!!error}
          variant={variant}
          {...inputProps}
        />
      )}

      {error && <Error>{error}</Error>}
    </Container>
  );
};

Input.defaultProps = {
  type: 'text',
  label: '',
  className: '',
  mask: '',
  returnUnmasked: false,
  colorLabel: '#FFFFFF',
  fullWidth: true,
  variant: '',
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  colorLabel: PropTypes.string,
  className: PropTypes.string,
  returnUnmasked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  mask: PropTypes.string,
  variant: PropTypes.string,
  typeInput: PropTypes.string,
};

export default Input;

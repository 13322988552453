import styled from 'styled-components';
// import devices from '~/styles/configs/devices';
import devices from '~/styles/configs/devices';

export const Container = styled.div`
  min-height: 100%;
  background-color: #f2f3f4;
`;

export const Content = styled.div`
  padding-top: 80px;
  max-width: 1440px;

  margin: 0 auto;

  @media ${devices.mobileS} {
    grid-template-columns: 1fr;
    margin: 0px 15px;
  }

  @media ${devices.laptop} {
    grid-template-columns: 1.4fr 1fr 1fr 1fr 1fr;
    margin-left: 260px;
  }
`;

import styledNormal, { css } from 'styled-components';

import { styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import fadeIn from '~/styles/animations/fadeIn';

export const Container = styledNormal.div`
  ${fadeIn};

  width: 100%;

  ${({ fullWidth }) => {
    if (fullWidth)
      return css`
        max-width: none;
      `;

    return css`
      max-width: 300px;
    `;
  }}
`;

export const FieldMaterial = styled(TextField)({
  border: '1px solid #e0e0e0',
  padding: 5,
  borderRadius: 25,
});

export const Field = styledNormal.input`
  -webkit-appearance: none;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  font-size: 16px;

  padding: 10px;

  border-radius: 25px;
  transition: border-color 200ms linear;

  &::placeholder {
    font-size: 16px;
    color: #aeaeb2;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #ff3b2f;

      &:hover {
        border-color: #f83019;
      }
    `}

  ${({ variant }) => {
    if (variant === 'border')
      return css`
        border: 1px solid #e0e0e0;

        :hover,
        :focus {
          border-color: #aeaeb2;
        }
      `;

    return '';
  }}
`;

export const Label = styledNormal.label`
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;

  ${({ color }) => {
    if (color === 'black')
      return css`
        color: '#000000';
      `;

    return css`
      color: ${color};
    `;
  }}
`;

export const Error = styledNormal.span`
  color: #ff3b2f;
  font-size: 12px;
`;

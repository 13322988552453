import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Private from '~/pages/_layouts/Private';
import { useDispatch, useSelector } from 'react-redux';
import { signOutRequest } from '~/store/modules/auth/actions';

const PrivateRouteCompany = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth.token);
  const company = useSelector((state) => state.auth.company);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token && !company) {
      dispatch(signOutRequest());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        token && company ? (
          <Private>
            <Component {...props} />
          </Private>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRouteCompany.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRouteCompany;

import React from 'react';
import Chart from 'react-apexcharts';

const Percent = ({ color, data, width }) => {
  const optionsRadial = {
    chart: {
      height: 200,
      type: 'radialBar',
    },
    colors: [color],

    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '50%',
        },

        dataLabels: {
          showOn: 'none',
          name: {
            offsetY: -10,
            show: false,
            color: '#888',
            fontSize: '13px',
          },
          value: {
            color: '#273645',
            offsetY: 10,
            fontSize: '25px',
            fontWeight: 'bold',
            show: true,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Cricket'],
  };

  const seriesRadial = [data];

  return (
    <Chart
      options={optionsRadial}
      series={seriesRadial}
      type="radialBar"
      width={width || '200'}
    />
  );
};

export default Percent;
